import Screen from "./Screen";
import React, { useEffect, useState, useRef } from "react";
import ValidateCalibration from "../components/Calibration/ValidateCalibration";
import Solutions from "../components/Calibration/Solutions";
import { background_calibration, bulbIllustration, happy_face, sad_face } from "../assets/Images";
import { useTranslation } from "react-i18next";
import { initialFlexFont } from "../assets/styles/styles";
import SolutionSvg from "../assets/Images/Calibration/SolutionSvg";
import Button from "../widgets/button/Button";
import Modal from "../components/modal/Modal";
import IndiceWidget from "../widgets/button/IndiceWidget";
import Indicator from "../components/Calibration/indicator";
import { Doctor } from "../components/Doctor";
import { useDispatch, useSelector } from "react-redux";
import { resetCalibrationAction, setAttemptsNbrAction, setCurrentAttemptsAction, setDoctorAction, setMaxSettingsNbrAction, setSolutionValueAction, setStageAction } from "../redux/actions/calibrationManager";
import { removeAllAndAdd } from "../redux/actions/sceneManager";
import { CONST_SCREENS } from ".";
import ClobazamButton from "../widgets/button/ClobazamButton";
import ValproateButton from "../widgets/button/ValproateButton";
import StiripentolButton from "../widgets/button/StiripentolButton";
import { StiripentolContent } from "../components/Calibration/content/stiripentol";
import { ClobazamContent } from "../components/Calibration/content/clobazam";
import { ValproateContent } from "../components/Calibration/content/Valproate";
import { SolutionIndice } from "../components/Calibration/content/solutionIndice";
import { ColorfulTranslation } from "../components/ColorfulTranslation";
import { musicAmbiance } from "../assets/sounds/howler";
import { MUSIC_SPRITE_NAMES } from "../assets/sounds/music";
import { useVoice } from "../utils/hooks/useVoice";
import { AUDIO_SPRITE_NAMES } from "../assets/sounds/audio";
import useSound from "use-sound";



const CalibrationScreen = (props) => {
    const { t } = useTranslation(undefined,{keyPrefix : "calibration"});
    const dispatch = useDispatch();
    const refSolution = useRef(); 
    useEffect(() => {
        musicAmbiance.stop();
        musicAmbiance.play(MUSIC_SPRITE_NAMES.Calibration);
      return () => {
        musicAmbiance.stop();
        musicAmbiance.play(MUSIC_SPRITE_NAMES.Background);
      }
    },[]);
    const doctorStatus = useSelector((state) => state.calibrationManager.doctor);
    const { attemptsNbr, maxSettingsNbr,currentAttempts, stage } = useSelector((state) => state.calibrationManager.settings);
    const solutions = useSelector((state) => state.calibrationManager.solutions);
    const [popUpIndice, setPopUpIndice] = useState(false);
    const [win,setWin] = useState(null); 
    const [popUpResult,setPopUpResult] = useState(false);
    useVoice(`${win === true ? "bravo" : "dommage"}-animation-2-stage-${stage + 1}`,popUpResult);
    useVoice(AUDIO_SPRITE_NAMES["instruction-1-mission-2"],stage === 0);
    useVoice(AUDIO_SPRITE_NAMES["instruction-2-mission-2"],stage === 1);
    function onSubmit(winValue = null, next = false) {
        if(stage === 1 && winValue === false) {
            refSolution.current && refSolution.current.classList.add("shake-bottom");
            setTimeout(() => {
                refSolution.current && refSolution.current.classList.remove("shake-bottom");
            }, 1000);
        }
        if(winValue === true) {
            setWin(true)
            setPopUpResult(true);
            
        } else if(next) {
            setWin(false)
            setPopUpResult(true);
        }
    }

    function endOfAttemptsNbrOrCorrectAnswerPopUp() {
        if(stage === 0) {
            setPopUpResult(false);
            setStage(1);
            setWin(null)
        }else {
            setPopUpResult(false);
            setTimeout(() => {
                dispatch(removeAllAndAdd(CONST_SCREENS.ENDING_SCREEN,{win}));
            }, 500);
        }
    }


    function setStage(value) {
        dispatch(setStageAction(value))
    }
    function setAttemptsNbr(number) {
        dispatch(setMaxSettingsNbrAction())
    }
    function setSolutions(params) {
        dispatch();
    }

    function reset() {
        dispatch(resetCalibrationAction())
    }




    useEffect(() => {
        if (stage === 1) {
            dispatch(setSolutionValueAction("Valproate", { status: true }));
            dispatch(setSolutionValueAction("Clobazam", { status: true }));
            dispatch(setSolutionValueAction("Stiripentol", { status: false, currentValue: 260 }));
            dispatch(setDoctorAction({ code: "green" }))
            dispatch(setCurrentAttemptsAction(2));
            dispatch(setDoctorAction({ text :""})) 
        }
        else if (stage === 0) {
            dispatch(setSolutionValueAction("Valproate", { status: false }));
            dispatch(setSolutionValueAction("Clobazam", { status: false }));
            dispatch(setSolutionValueAction("Stiripentol", { status: true }));
        }

        dispatch(setAttemptsNbrAction(maxSettingsNbr));
    }, [stage])

    useEffect(() => {
        initialFlexFont();
        return () => {
            reset();
        }
    }, []);


    return (
        <Screen component={props.props.component}>
            {/* The screen is relative and here we can add what we want here */}

            <div className="backgroundGuitarHero">
                <div>
                    {/* this place is relative to 100% */}
                    <img
                        className="background"
                        src={background_calibration}
                        alt=""
                    ></img>
                    <div className="indicator">
                        <Indicator code={doctorStatus.code} />
                    </div>
                    <Doctor text={doctorStatus.text && t(`doctor.${doctorStatus.text}`)} crop_buttom={59.5} />
                    <ValproateButton>
                        <ValproateContent/>
                    </ValproateButton>
                    <ClobazamButton >
                        <ClobazamContent/>
                    </ClobazamButton >
                    <StiripentolButton>
                        <StiripentolContent/>
                    </StiripentolButton>                    
                    <p className="dosage flexFont"><span>{t("gui.bon_dosage")}</span></p>
                </div>
            </div>
            <Solutions
                solutions={solutions}
                attemptsNbr={attemptsNbr}
            />

            <ValidateCalibration
                solutions={solutions}
                attemptsNbr={attemptsNbr}
                onSubmit={onSubmit}
                currentAttempts = {currentAttempts}
                stage={stage}
            />

            {/* attemps  */}
            <div className="heartContainer flexFont">
                <div>
                    <h1>{t("gui.phase")}</h1>
                    {/* <h1>{maxSettingsNbr - attemptsNbr + 1 < maxSettingsNbr ? maxSettingsNbr - attemptsNbr + 1 : maxSettingsNbr }/{maxSettingsNbr}</h1> */}
                    <h1>{currentAttempts < maxSettingsNbr ? currentAttempts + 1 : maxSettingsNbr }/{maxSettingsNbr}</h1>

                </div>

            </div>

            <div ref={refSolution} className="finalSolution">
                <div>
                    <SolutionSvg />
                </div>
            </div>

            <div className="guitarhero-Text-container heartbeat forCallibration flexFont">
                <div>
                    <div
                        style={{
                            width: "40%",
                            padding: "2%",
                            boxSizing: "border-box",
                        }}
                    >
                        <img
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                            }}
                            src={bulbIllustration}
                            alt=""
                        ></img>
                    </div>

                    <div className="center-consigne">
                        <div>
                            <h1>{t(`instruction_stage_${stage}.title`)}</h1>
                            <p>{t(`instruction_stage_${stage}.instruction`)}</p>
                        </div>
                        <h2>{t(`instruction_stage_${stage}.footer`)}</h2>
                    </div>
                </div>
            </div>


            <IndiceWidget>
                <>
                        <SolutionIndice/>
                </>
            </IndiceWidget>

            <Modal setShow={setPopUpIndice} isOpen={popUpIndice} outSideClose={true}>
                <div>                    
                <section className="content center-text">
                    <h1 className="title"><ColorfulTranslation t={t} i18nKey={"valproate.indice_title"}/></h1>
                    <section> <p><ColorfulTranslation t={t} i18nKey={"valproate.indice_content"}/></p>
                    </section>
                </section>

                </div>
                <div>
                    <Button top={"90%"} left={"43%"} className="flexFont primary" ratio={"15:6"} scale={1} onClick={() => setPopUpIndice(false)}>{t("gui.compris")}</Button>
                </div>
            </Modal>
            <Modal setShow={setPopUpResult} isOpen={popUpResult}>
                <div>                    
                <section className="content center-text">
                    <h1 className="title">{win === true ? t(`popup_win_lose.stage_${stage}.win_title`) : t(`popup_win_lose.stage_${stage}.lose_title`)}</h1>

                    <section>
                         <p style={{padding :"0 13%"}}><ColorfulTranslation t={t} i18nKey={win === true ? `popup_win_lose.stage_${stage}.win_text` : `popup_win_lose.stage_${stage}.lose_text` }/></p>
                         <img width={"10%"} alt="" src={win === true ? happy_face : sad_face}/>
                    </section>
                </section>
                </div>
                <div>
                    <Button top={"90%"} left={"43%"} className="flexFont primary" ratio={"15:6"} scale={1} onClick={() => endOfAttemptsNbrOrCorrectAnswerPopUp()}>{t("gui.compris")}</Button>
                </div>
            </Modal>

        </Screen>
    );
};

export default CalibrationScreen;
