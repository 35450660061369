import SplashScreen from "./SplashScreen";
import Intro from "./Intro";
import GuitarHero from "./Guitarhero";
import EndingScreen from "./EndingScreen";
import CalibrationScreen from "./CalibrationScreen";
import GuitarHeroTutorial from "./guitarHeroTutorial";
import AfterGuitarHero from "./AfterGuitarHero";

export const CONST_SCREENS = {
    SPLASHSCREEN: "splashScreen",
    INTRO: "Intro",
    GUITAR_HERO: "GuitarHero",
    ENDING_SCREEN: "EndingScreen",
    CALIBRATION_SCREEN: "CalibrationScreen",
    GUITAR_HERO_TUTORIAL: "GuitarHeroTutorial",
    AFTER_GUITAR_HERO: "AfterGuitarHero"
}

const screens = {
    [CONST_SCREENS.SPLASHSCREEN]: SplashScreen,
    [CONST_SCREENS.INTRO]: Intro,
    [CONST_SCREENS.GUITAR_HERO]: GuitarHero,
    [CONST_SCREENS.ENDING_SCREEN]: EndingScreen,
    [CONST_SCREENS.CALIBRATION_SCREEN]: CalibrationScreen,
    [CONST_SCREENS.GUITAR_HERO_TUTORIAL]: GuitarHeroTutorial,
    [CONST_SCREENS.AFTER_GUITAR_HERO] : AfterGuitarHero
}
export default screens;