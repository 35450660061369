import {useTranslation } from 'react-i18next'
import { ColorfulTranslation } from '../../ColorfulTranslation';
import { useVoice } from '../../../utils/hooks/useVoice';

export function ClobazamContent({}) {
    const { t } = useTranslation(undefined,{keyPrefix : "calibration.clobazam"});
    useVoice("clobazam",true);
        return (
        <section className="content center-text">
            <h1 className="title"><ColorfulTranslation t={t} i18nKey={"info_title"}/></h1>
            <section>
                <p>
                <ColorfulTranslation t={t} i18nKey={"info_content"}/>
                </p>
            </section>
        </section>
    );
}
