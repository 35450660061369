import { useEffect, useState } from "react";
import { target } from "../../assets/Images";
import Modal from "../../components/modal/Modal";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { ColorfulTranslation } from "../../components/ColorfulTranslation";
import { flexFontStyle } from "../../assets/styles/styles";
import { soundFx } from "../../assets/sounds/howler";
import { FX_SPRITE_NAMES } from "../../assets/sounds/fx";

function TargetWidget({ scale = 1, width = 6, height = 6, left, top, className, style }) {
    const [showRef, setshowRef] = useState(false);
    function click() {
        soundFx.play(FX_SPRITE_NAMES.button_on_off_064);

        setshowRef((sr) => !sr)
    }
    return (
        <>
            <Button onClick={click} src={target} ratio={`${width}:${height}`} scale={scale} left={"2%"} top={"80%"}></Button>

            <Modal  style={{width : "75%"}} outSideClose={true} xButon={true} setShow={setshowRef} isOpen={showRef}>
                <TargetContent />
                <div>
                </div>
            </Modal>
        </>
    )
}

export default TargetWidget;

export function TargetContent({className=""}) {
    const { t } = useTranslation(undefined, { keyPrefix: "general.popup_target" });
    useEffect(() => {
            flexFontStyle();
    },[])
    return <section className={`${className} content center-text flexFont`}>
        <h1 className="title"><ColorfulTranslation t={t} i18nKey={"title_1"} /></h1>
        <section>
            <p>
                <ColorfulTranslation t={t} i18nKey={"text_1"} />
            </p>
        </section>
        <h1 className="title"><ColorfulTranslation t={t} i18nKey={"title_2"} /></h1>
        <section>
            <p>
                <ColorfulTranslation t={t} i18nKey={"text_2"} />
            </p>
        </section>
    </section>
}