export const musicSprites =  {
  "Background_preview": [
    0,
    32000,
    true
  ],
  "Guitar_hero_preview": [
    33000,
    67200,
    true
  ],
  "Second_game_preview": [
    102000,
    86041.65532879818,
    true
  ]
};

export const MUSIC_SPRITE_NAMES =  {
"Background":  "Background_preview",
"Guitar_hero": "Guitar_hero_preview",
"Calibration": "Second_game_preview",
};
