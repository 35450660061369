import { Howl } from "howler";
import SOUNDS, { sounds } from ".";

export const soundFx = new Howl({
    src : SOUNDS[sounds.fx].src,
    sprite: SOUNDS[sounds.fx].sprites
});

export const musicAmbiance = new Howl({
    src : SOUNDS[sounds.music].src,
    sprite: SOUNDS[sounds.music].sprites,
    volume : 0.3,
});



// default 
export let audioAmbiance = new Howl({
    src : SOUNDS[sounds.sound_fr].src,
    sprite: SOUNDS[sounds.sound_fr].sprites
});

export const changeContextAudio = (lang = "fr") => {
    console.log("hello",lang);
    if(audioAmbiance) {
        audioAmbiance.stop();
        audioAmbiance.unload();
        audioAmbiance = null ;
    }

    if(lang === "fr") {
        audioAmbiance = new Howl({
            src : SOUNDS[sounds.sound_fr].src,
            sprite: SOUNDS[sounds.sound_fr].sprites
        });
    } else {
        audioAmbiance = new Howl({
            src : SOUNDS[sounds.sound_en].src,
            sprite: SOUNDS[sounds.sound_en].sprites
        });
    }
}