import React from "react";

const Validate = ({ onClick = () => {} }) => {
  return (
    <div className="validate-btn flexFont" onClick={onClick}>
      <div style={{ fontSize: "2em" }}>Valider mon choix</div>
    </div>
  );
};

export default Validate;
