import Screen from "./Screen";
import React from "react";
import { background_guitarHero } from "../assets/Images";
import tutorialVideoMp4 from "../assets/videos/tutorial.mp4";
import Button from "../widgets/button/Button";
import { useDispatch } from "react-redux";
import { addScene, changeOrderScene, deleteScene } from "../redux/actions/sceneManager";
import { CONST_SCREENS } from ".";
import { useTranslation } from "react-i18next";
import tutorial from "../assets/videos/tutorial.mp4"
const GuitarHeroTutorial =  props => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const skipAction = () => {
    dispatch(addScene(CONST_SCREENS.GUITAR_HERO));
    dispatch(deleteScene(CONST_SCREENS.GUITAR_HERO_TUTORIAL));
  }
  return  <Screen component={props.props.component}>
    <div className="guitarHeroContainer">
      <div>
      <div className="backgroundGuitarHero">
        <div>
          {/* this place is relative to 100% */}
          {/* <img className="background" src={background_guitarHero} alt=""></img> */}
          <video src={"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"} width="100%" height="100%" controls></video>
        </div>
      </div>
      <Button ratio={"2:1"} className={"skipTutorial"} top={"5%"} left={"90%"} scale={5} onClick={skipAction}>{t("guitar_hero_tutorial.nextButton")}</Button>

      </div>
    </div>
  </Screen>
};

export default GuitarHeroTutorial ;


export function ContentTutorial({onClick=() => {}}) {
  return <div className="guitarHeroContainer">
  <div>
  <div className="backgroundGuitarHero">
    <div>
      <video src={tutorial} width="100%" height="100%" muted ={true} loop={true} controls={false} autoPlay={true}></video>
    </div>
  </div>

  </div>
</div>
}