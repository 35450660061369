import Screen from "./Screen";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    backgroundEnding1,
    backgroundEnding2,
    backgroundEnding3,
    happy_faces,
    molecule_bg,
    poster,
    qrcode,
    sad_faces,
    stri,
    stri_unactive,
    table,
    table_en
} from "../assets/Images";
import { flexFontStyle } from "../assets/styles/styles";
import { useTranslation } from "react-i18next";
import { ColorfulTranslation } from "../components/ColorfulTranslation";
import Button from "../widgets/button/Button";
import Modal from "../components/modal/Modal";
import { removeAllAndAdd } from "../redux/actions/sceneManager";
import { CONST_SCREENS } from ".";
import { RefContent } from "../widgets/button/RefWidget";
import { SecurityContent } from "../widgets/ContentSecurity";
import { musicAmbiance, soundFx } from "../assets/sounds/howler";
import { FX_SPRITE_NAMES } from "../assets/sounds/fx";
import { useVoice } from "../utils/hooks/useVoice";
import { AUDIO_SPRITE_NAMES } from "../assets/sounds/audio";
import { LangContext } from "../translation/context_lang";
//

import diacomit_moa_en from "../assets/videos/diacomit_moa_en.webm";
import diacomit_moa_fr from "../assets/videos/diacomit_moa_fr.webm";
import { MUSIC_SPRITE_NAMES } from "../assets/sounds/music";
import { RcpContent } from "../widgets/button/RcpContent";
const video_url = {
    fr : diacomit_moa_fr,
    en : diacomit_moa_en

}
const EndingScreen = (props) => {
    const dispatch = useDispatch();
    const {value : {isLangSet,lang}} = useContext(LangContext);
    const [index, setIndex] = useState(0);
    const { t } = useTranslation(undefined, { keyPrefix: "ending.end_screen" });
    const [showRef, setshowRef] = useState(false);
    const [showSecurity, setShowSecurity] = useState(false);
    const [showRcp, setshowRcp] = useState(false);
    function toggleRef() {
        soundFx.play(FX_SPRITE_NAMES["button_on_off_064"]);
        setshowRef((sr) => !sr);
    }
    function toggleSecurity() {
        soundFx.play(FX_SPRITE_NAMES["button_on_off_064"]);
        setShowSecurity((sr) => !sr);
    }
    function toggleRcp() {
        soundFx.play(FX_SPRITE_NAMES["button_on_off_064"]);
        setshowRcp((sr) => !sr);
    }
    useEffect(() => {
        flexFontStyle();
    }, [index]);
    function handleIndex() {
        soundFx.play(FX_SPRITE_NAMES["TM_GLITCH UI_Clicks dry_11"]);
        if (index < 3) setIndex((i) => i + 1);
    }
    function home() {
      soundFx.play(FX_SPRITE_NAMES["button_on_off_064"]);
      dispatch(removeAllAndAdd(CONST_SCREENS["SPLASHSCREEN"]))
    }
    useVoice(AUDIO_SPRITE_NAMES[`${props.props.win ? "bravo" : "dommage"}-animation-2`],index === 0);
    useVoice(AUDIO_SPRITE_NAMES["grace"],index === 1);
    useVoice(AUDIO_SPRITE_NAMES["decouvrez"],index === 2);
    useVoice(AUDIO_SPRITE_NAMES["merci"],index === 3);
    return (
        <Screen component={props.props.component}>
            {/* The screen is relative and here we can add what we want here */}
            {index === 0 && <BackgroundWinLose active={props.props.win} />}
            {index === 0 && <WinLose win={props.props.win} />}
            {index === 1 && <Background1 />}
            {index === 1 && <Content1 lang={lang}/>}

            {index === 2 && <Background2 />}
            {index === 2 && <Content2 video={lang === "" ? video_url["fr"] : video_url[lang]}/>}

            {index === 3 && <Background3 />}
            {index === 3 && <Content3 />}
            {index !== 3 && (
                <Button
                    onClick={handleIndex}
                    top={"83%"}
                    left={"42.5%"}
                    className="flexFont primary"
                    ratio={"15:6"}
                    scale={1}
                >
                    <span style={{ fontSize: "2em" }}>{t("next")}</span>
                </Button>
            )}
            {index === 3 && (
                <>
                    <Button
                        onClick={toggleRef}
                        top={"83%"}
                        left={lang === "fr"? "18%" : "25%"}
                        className="flexFont primary"
                        ratio={"13:6"}
                        scale={1}
                    >
                        <span style={{ fontSize: "1.5em" }}>
                            <ColorfulTranslation
                                t={t}
                                i18nKey={"ref_button_text"}
                            />
                        </span>
                    </Button>
                    { lang === "fr" && <Button
                        style={{ textAlign: "center" }}
                        onClick={toggleSecurity}
                        top={"83%"}
                        left={"35%"}
                        className="flexFont primary"
                        ratio={"13:6"}
                        scale={1}
                    >
                        <span style={{ fontSize: "1.5em" }}>
                            <ColorfulTranslation
                                t={t}
                                i18nKey={"security_button_text"}
                            />
                        </span>
                        </Button>
                    }
                    <Button
                        onClick={toggleRcp}
                        top={"83%"}
                        left={lang === "fr"? "52%" : "43.4%"}
                        className="flexFont primary"
                        ratio={"13:6"}
                        scale={1}
                    >
                        <span style={{ fontSize: "1.5em" }}>
                            <ColorfulTranslation
                                t={t}
                                i18nKey={"rcp_button_text"}
                            />
                        </span>
                    </Button>
                    <Button
                        onClick={home}
                        top={"83%"}
                        left={lang === "fr"? "69%" : "61.8%"}
                        className="flexFont primary"
                        ratio={"13:6"}
                        scale={1}
                    >
                        <span style={{ fontSize: "1.5em" }}>
                            <ColorfulTranslation
                                t={t}
                                i18nKey={"home_button_text"}
                            />
                        </span>
                    </Button>
                </>
            )}

            <Modal
                setShow={toggleRef}
                xButon={true}
                isOpen={showRef}
                outSideClose={true}
            >
                 <RefContent />   
                <div></div>
            </Modal>

            <Modal
                setShow={toggleSecurity}
                isOpen={showSecurity}
                outSideClose={true}
            >
                <SecurityContent />
                <div></div>
            </Modal>

            <Modal
                setShow={toggleRcp}
                xButon={true}
                isOpen={showRcp}
                outSideClose={true}
            >
                <RcpContent />
                <div></div>
            </Modal>
        </Screen>
    );
    };

export default EndingScreen;

function WinLose({ win }) {
    const { t } = useTranslation(undefined, { keyPrefix: "ending.result" });
    // useEffect(() => {
    //    if(win) soundFx.play(FX_SPRITE_NAMES.game_upgrade);
    //    else soundFx.play(FX_SPRITE_NAMES.game_over)
    //   },[win])
    return (
        <div className="intro__block container wider_container flexFont fade-in">
            <h1
                style={{
                    padding: "0.5em 1em",
                    paddingBottom: "0",
                    fontSize: "0.5em",
                }}
                className="intro__block__center"
            >
                <ColorfulTranslation
                    t={t}
                    i18nKey={`${win ? "win_title" : "lose_title"}`}
                />
            </h1>
            <h5
                style={{ padding: "0em 1em", paddingTop: "0" }}
                className="intro__block__center font-size-03"
            >
                <ColorfulTranslation
                    t={t}
                    i18nKey={`${win ? "win_sub_title" : "lose_sub_title"}`}
                />
            </h5>
            <img
                width={"25%"}
                className="intro__block__center"
                src={win ? happy_faces : sad_faces}
                alt=""
            />
            <img
                width={"10%"}
                className="intro__block__center"
                src={win ? stri : stri_unactive}
                alt=""
            />
            <div>
                <p className="intro__block__center font-size-02">
                    <ColorfulTranslation t={t} i18nKey={"text_1"} />
                </p>
                <p className="intro__block__center font-size-02">
                    <ColorfulTranslation t={t} i18nKey={"text_2"} />
                </p>
                <p className="intro__block__center font-size-02">
                    <ColorfulTranslation t={t} i18nKey={"text_3"} />
                </p>
            </div>
            <div>
                <p className="intro__block__center font-size-02">
                    <ColorfulTranslation t={t} i18nKey={"text_4"} />
                </p>
            </div>
        </div>
    );
}
function BackgroundWinLose({ active }) {
    return (
        <div className="screen-background fade-in">
            <div>
                <img
                    style={{ opacity: `${active ? 1 : 0.4}` }}
                    className="img floating"
                    alt=""
                    height={"100%"}
                    width={"100%"}
                    src={molecule_bg}
                />
            </div>
        </div>
    );
}

function Background1({}) {
    return (
        <div className="screen-background fade-in">
            <div>
                <img
                    className="img"
                    alt=""
                    height={"100%"}
                    width={"100%"}
                    src={backgroundEnding2}
                />
            </div>
        </div>
    );
}

function Background2({}) {
    return (
        <div className="screen-background fade-in">
            <div>
                <img
                    className="img"
                    alt=""
                    height={"100%"}
                    width={"100%"}
                    src={backgroundEnding1}
                />
            </div>
        </div>
    );
}

function Background3({}) {
    return (
        <div className="screen-background fade-in">
            <div>
                <img
                    className="img"
                    alt=""
                    height={"100%"}
                    width={"100%"}
                    src={backgroundEnding3}
                />
            </div>
        </div>
    );
}

function Content1({lang="fr"}) {
    const { t } = useTranslation(undefined, {
        keyPrefix: "ending.ending_screen_1",
    });

    return (
        <div className="intro__block container wider_container flexFont fade-in">
            <div>
                {/* paragraph 1  */}
                <p className="intro__block__center font-size-02">
                    <ColorfulTranslation t={t} i18nKey={"p_1_text_1"} />
                </p>
                <p className="intro__block__center font-size-02">
                    <ColorfulTranslation t={t} i18nKey={"p_1_text_2"} />
                </p>
            </div>
            {/* paragraph 2  */}
            <div>
                <p className="intro__block__center font-size-02">
                    <ColorfulTranslation t={t} i18nKey={"p_2_text_1"} />
                </p>
                <p className="intro__block__center font-size-02">
                    <ColorfulTranslation t={t} i18nKey={"p_2_text_2"} />
                </p>
            </div>
            {/* paragraph 3  */}
            <div>
                <h5 className="intro__block__center font-size-02">
                    <ColorfulTranslation t={t} i18nKey={"p_3_text_1"} />
                </h5>
            </div>
            {/* table  */}
            <img
                width={"50%"}
                className="intro__block__center show-clip-path"
                src={lang === "fr" ? table : table_en }
                alt=""
            />
            {/* paragraph 4  */}
            <div>
                <h5 className="intro__block__center font-size-02">
                    <ColorfulTranslation t={t} i18nKey={"p_4_text_1"} />
                </h5>
            </div>
            {/* paragraph 5  */}
            <div>
                <h5 className="intro__block__center font-size-02">
                    <ColorfulTranslation t={t} i18nKey={"p_5_text_1"} />
                </h5>
                <h5 className="intro__block__center font-size-02">
                    <ColorfulTranslation t={t} i18nKey={"p_5_text_2"} />
                </h5>
            </div>
        </div>
    );
}

function Content2({video}) {
    const { t } = useTranslation(undefined, {
        keyPrefix: "ending.ending_video",
    });
    const [play,setPlay] = useState(null);
    useEffect(() => {
        if(play === true) {
            musicAmbiance.stop();
        } else if(play === false) {
            musicAmbiance.play(MUSIC_SPRITE_NAMES.Background);
        }
        return () => {
            if(play === true)  musicAmbiance.play(MUSIC_SPRITE_NAMES.Background);      
        }
    },[play])
    return (
        <div className="intro__block container wider_container middle flexFont fade-in">
            <h5
                style={{ marginBottom: "3%", marginTop: "6%" }}
                className="intro__block__center font-size-03"
            >
                <ColorfulTranslation t={t} i18nKey={"text_1"} />
            </h5>
            <video
                poster={poster}
                style={{
                    display: "block",
                    margin: "0 auto",
                    padding: "0.5%",
                    background: "black",
                }}
                autoPlay={true}
                playsInline = {true}
                disablePictureInPicture
                src={video}
                width="66%"
                controls={true}
                onPlay={() => setPlay(true)}
                onPause={() => setPlay(false)}
    
            ></video>
        </div>
    );
}

function Content3({}) {
    const { t } = useTranslation(undefined, { keyPrefix: "ending.end_screen" });

    return (
        <div className="intro__block container wider_container middle flexFont fade-in">
            <h1
                style={{ marginBottom: "3%", fontSize: "0.65em" }}
                className="intro__block__center"
            >
                <ColorfulTranslation t={t} i18nKey={"title"} />
            </h1>
            <h5
                style={{ fontSize: "0.35em" }}
                className="intro__block__center font-size-02"
            >
                <ColorfulTranslation t={t} i18nKey={"sub_title"} />
            </h5>
        </div>
    );
}
