import { createContext, useState } from "react";

export const LangContext = createContext();

export const LangProvider = ({ children }) => {
    const [value, setValue] = useState({ isLangSet: false, lang: "" });
    const changeValue = (newValue = {}) => {
        setValue((oldValue) => {
            return {
                ...oldValue,
                ...newValue
                
                
            }
        })
    }
    return <LangContext.Provider value={{ value, changeValue }}>
        {children}
    </LangContext.Provider>
} 