import {
  CHANGE_ORDER_SCENE,
  DELETE_SCENE,
  ADD_SCENE,
  REMOVE_ALL_AND_ADD,
} from "../constants/sceneManager";
import { v4 as uuidv4 } from 'uuid';
export function addScene(Scene,payload) {
  return { type: ADD_SCENE, payload : {_uid : uuidv4(),component : Scene,...payload} };
}
export function deleteScene(Scene,payload) {
  return { type: DELETE_SCENE, payload : {component : Scene,...payload} };
}
export function changeOrderScene(Scene,payload) {
  return { type: CHANGE_ORDER_SCENE, payload : {component : Scene,...payload} };
}
export function removeAllAndAdd(Scene,payload) {
  return { type: REMOVE_ALL_AND_ADD, payload : {_uid : uuidv4(),component : Scene,...payload} };

}