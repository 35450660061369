import { createStore } from "redux";
import rootReducer from "./reducers";
// getState for reading the current state of the application
// dispatch for dispatching an action
// subscribe for listening to state changes

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f
);

export default store;
