import {combineReducers} from 'redux';
import sceneManagerRootReducer from './sceneManager'
import profileManagerRootReducer from './profileManager'
import soundManagerRootReducer from './soundManager'
import progressManagerRootReducer from './progressManager';
import quizManagerRootReducer from './quizManager';
import calibrationRootReducer from './calibration';
export default combineReducers({
    SceneManager : sceneManagerRootReducer,
    profileManager : profileManagerRootReducer,
    soundManager : soundManagerRootReducer,
    progressManager:progressManagerRootReducer,
    quizManager : quizManagerRootReducer,
    calibrationManager : calibrationRootReducer,
})