import { useTranslation } from "react-i18next";
import { ColorfulTranslation } from '../../ColorfulTranslation';

export function SolutionIndice({}) {
    const { t } = useTranslation(undefined,{keyPrefix : "calibration"});

    return <section className="content center-text">
        <h1 className="title">{t("stiripentol.indice_title")} 1</h1>
        <section>
            <p><ColorfulTranslation t={t} i18nKey={"stiripentol.indice_content"} /></p>
        </section>
        <h1 className="title">{t("clobazam.indice_title")} 2</h1>
        <section>
            <p><ColorfulTranslation t={t} i18nKey={"clobazam.indice_content"} /></p>
        </section>
        <h1 className="title">{t("valproate.indice_title")} 3</h1>
        <section>
            <p><ColorfulTranslation t={t} i18nKey={"valproate.indice_content"} /></p>
        </section>
    </section>
}