import { useTranslation } from "react-i18next";
import { qrcode } from "../../assets/Images";

export const RcpContent = () => {
    const { t } = useTranslation (undefined,{keyPrefix : "general.popup_rcp"});
    return <div className="content ref">
                    <h1 className="title">
                        {t("title")}
                    </h1>
                    <section>
                        <div style={{display :"flex",alignItems :"center",flexDirection :"column"}}>
                            <p>
                            {t("text_1")}
                            </p>
                            <br />
                            <p style={{display :"block",width :"100%"}}><a href="https://base-donnees-publique.medicaments.gouv.fr/">
                                https://base-donnees-publique.medicaments.gouv.fr/
                            </a></p>
                            <br />
                            <img alt="" src={qrcode} width={"30%"} />
                        </div>
                    </section>
                </div>
}