import {FILL_BACTODEX ,
        ACCESS_TO_CHAPTER,
        EDIT_SCORE,        
        UNLOCK_GUIDELINE,
        UNLOCK_TESTLABO,
        UNLOCK_QUIZ_1,
        UNLOCK_ANTIBIOGRAMME,
        UNLOCK_QUIZ_2,
        UNLOCK_BOARD,
        REPLY_TO_TESTLABO ,
        REPLY_TO_ANTIBIOGRAMME,
        REPLY_TO_QUIZ_1,
        REPLY_TO_QUIZ_2,  
} from "../constants/progressManager";

const initialState={
    score:0,
    chapters:[],
    bacToDex:[{guideline:false}],
    board:[{unlocked:false}],
    testLabo :[{unlocked:false}],
    quiz_1:[{unlocked:false}],
    antibiogramme:[{unlocked:false}],
    quiz_2:[{unlocked:false}], 
 }
  
 function progressManagerRootReducer(state = initialState, action) {
   switch (action.type) {

       case FILL_BACTODEX:   
           return {...state,
            bacToDex:[...state.bacToDex,action.payload]
           }

        case ACCESS_TO_CHAPTER:
            return {...state,
            chapters:[...state.chapters, ...action.payload]
            }  

        case EDIT_SCORE:
            return {...state,
            score:state.score += action.payload
            } 

        case UNLOCK_BOARD:
            return {...state,
            board:state.board = toUnlock(state.board)                
            }

        case UNLOCK_TESTLABO:
            return {...state,
            testLabo:state.testLabo = toUnlock(state.testLabo)
            }
        case REPLY_TO_TESTLABO :
            return {...state,
            testLabo:[...state.testLabo,action.payload]
            }

        case UNLOCK_QUIZ_1:
            return {...state,
            quiz_1:state.quiz_1 = toUnlock(state.quiz_1)
            }
        case REPLY_TO_QUIZ_1:
            return {...state,
                quiz_1:[...state.quiz_1,action.payload]
                }
        case UNLOCK_ANTIBIOGRAMME:
            return {...state,
            antibiogramme:state.antibiogramme = toUnlock(state.antibiogramme)
            }
        case REPLY_TO_ANTIBIOGRAMME:
            return {...state,
                antibiogramme:[...state.antibiogramme,action.payload]
                }
         case UNLOCK_GUIDELINE:
            return {...state,
            bacToDex:state.bacToDex = toUnlock(state.bacToDex)
            }
        case UNLOCK_QUIZ_2:
            return {...state,
            quiz_2:state.quiz_2 = toUnlock(state.quiz_2)
            }
        case REPLY_TO_QUIZ_2:
            return {...state,
                quiz_2:[...state.quiz_2,action.payload]
                }

       default:
           return state;
   }
}

function toUnlock(arr) {
    if(arr[0].unlocked != undefined){
        arr.splice(0,1,{unlocked:true})
    }else{ arr.splice(0,1,{guideline:true})}

    return arr
   }

export default progressManagerRootReducer;