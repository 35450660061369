import { AUDIO_SPRITE_NAMES, audioSprites_fr } from "../../../assets/sounds/audio";
import { audioAmbiance } from "../../../assets/sounds/howler";
import { calcDistanceBetweenTwoPoint, isBetween } from "../../../utils";

export const checkAnswers = (solutions) => {
    let result = [];
    let win = true ;
    for (let i = 0; i < solutions.length; i++) {
      const [min,max] = solutions[i].successInterval;
      const currentValue = solutions[i].currentValue;
      const currentValueInPosition = isBetween(currentValue,min,max);

      const submittedValue = solutions[i].submittedValue;
      

      // Calculate the currentValue distance is closer to the taget (successInterval) than the last attempts 
      const [sMin,sMax] = solutions[i].successInterval;
      const minDistanceCurrentValue = Math.min(calcDistanceBetweenTwoPoint(sMin,currentValue),calcDistanceBetweenTwoPoint(sMax,currentValue))
      const minDistanceSubmittedValue = Math.min(calcDistanceBetweenTwoPoint(sMin,submittedValue),calcDistanceBetweenTwoPoint(sMax,submittedValue))
      let closeFromTheLastTime =  "=" ; // means the player didn't change the parametre from the last time
      if(minDistanceCurrentValue > minDistanceSubmittedValue) {
        closeFromTheLastTime = "-" ; // this mean negative = going far from the interval regarding the last attemps
      } else if (minDistanceCurrentValue < minDistanceSubmittedValue){
        closeFromTheLastTime = "+"; // this mean positive = going closer or on the interval from the last time 
      }
      let thisWin = solutions[i].status === false ||  currentValueInPosition ;
      win = win && thisWin ;
      result.push({
            currentValueInPosition,
            status : solutions[i].status,
            id : solutions[i].id,
            currentValue,
            submittedValue,
            result: solutions[i].status ? !currentValueInPosition  :  false,
            closeFromTheLastTime,

      })
  
    }
    return [result,win] ;
  };
  


  export function doctorMessage(result, stage) {
    // if stage 1 Stiripentol
    let code = "";
    let text = "";
    let valproate = true;
    let clobazam = true;
    for (let index = 0; index < result.length; index++) {
        const solutionResult = result[index];
        const { id, currentValue,currentValueInPosition,closeFromTheLastTime } = solutionResult;
        if (stage === 0) {
            if (id === "Stiripentol") {
                if (isBetween(currentValue, 0, 150)) code = "red";
                else if (isBetween(currentValue, 150, 200)) code = "orange";
                else if (isBetween(currentValue, 200, 240)) code = "yellow";
                else if (isBetween(currentValue, 240, 260)) code = "green";

                switch (closeFromTheLastTime) {
                    case "+":
                        text = "stage_0_+"
                        break;
                    case "-":
                        text = "stage_0_-"
                        break;
                    default:
                        // text = "stage_0_="
                        text = ""
                        break;
                }
            } else {
                continue ;
            }
        } else if(stage > 0) {
            if(id === "Valproate") {
                if(currentValueInPosition) {
                    text = "stage_1_Clobazam";
                    valproate = false;
                }
                
            }else if(id === "Clobazam") {
                if(currentValueInPosition) {
                    text = "stage_1_Valproate";
                    clobazam = false;
                }
            }else {
                continue ;
            }
        }
    }

    if(stage > 0 ) {
        code = "green";
        if(clobazam  && valproate) {
            text = "stage_1_both"
        }else if(!clobazam && !valproate) {
            text = "yep"
        }
    }
    switch (text) {
        case "stage_0_+":
            audioAmbiance.play(AUDIO_SPRITE_NAMES["bonne-voie"])
            break;
            case "stage_0_-":
            audioAmbiance.play(AUDIO_SPRITE_NAMES["emmm"])
            break;
            case "stage_1_Valproate":
                audioAmbiance.play(AUDIO_SPRITE_NAMES["appetit"])
            break;
            case "stage_1_Clobazam":
                audioAmbiance.play(AUDIO_SPRITE_NAMES["irritabilite"])
            break;
            case "stage_1_both":
                audioAmbiance.play(AUDIO_SPRITE_NAMES["irritabilite-appetit"])
            break;
            case "yep":
                audioAmbiance.play(AUDIO_SPRITE_NAMES["oui"])
            break;
        default:
            break;
    }
    return { code, text }
}

