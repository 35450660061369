import { Children, useEffect } from "react";
import { initialFlexFont } from "../../assets/styles/styles";
import { soundFx } from "../../assets/sounds/howler";
import { FX_SPRITE_NAMES } from "../../assets/sounds/fx";

const defaultRatio = "16:9";
const [default_W, default_H] = defaultRatio.split(":");
const ratioFriction = parseInt(default_H) / parseInt(default_W);
function Button({
  scale = 1,
  width = 1,
  height = 1,
  ratio = "1:1",
  left = 0,
  top = 0,
  addWidth = 0,
  className,
  onClick,
  src,
  children,
  disabled = false,
  style = {},
  visible = true,
  onMouseUp=() => {},
  onMouseLeave=() => {},
  onMouseDown= () => {},
}) {
  function click() {
    !disabled && onClick && onClick();
  }
  function MouseUp() {
    !disabled && onMouseUp();
  }
  function MouseLeave() {
    !disabled && onMouseLeave();
  }
  function MouseDown(e) {
    !disabled  && onMouseDown(e);
  }
  useEffect(() => {
    initialFlexFont()
  },[scale,visible])
  let [w, h] = ratio.split(":");
  if(!visible) return null
  return (
    <div
      style={{
        left,
        top,
        width: `${width * scale * parseInt(w) * ratioFriction + addWidth}%`,
        height: `${height * scale * parseInt(h)}%`,
        ...style,
      }}
      className={className ? `widget ${disabled ? "disabled" : ""} ${className}` : `widget ${disabled ? "disabled" : ""}`}
      onClick={click}
      onMouseDown={(e) => MouseDown(e)}
      onMouseLeave={MouseLeave}
      onMouseUp={MouseUp}
      onTouchStart={(e) => MouseDown(e)}
      onTouchEnd={MouseLeave}
      onContextMenu={(e) => e.preventDefault() }
    >
      <div className="splachscreen_play__block">
        {children && <div>{children}</div>}
        {src && <img src={src} alt={"ref"}></img>}
      </div>
    </div>
  );
}

export default Button;
