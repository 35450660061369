export const audioSprites_fr =  {
    "appetit": [
      0,
      4651.8820861678005
    ],
    "bonjour": [
      6000,
      21367.369614512474
    ],
    "bonne-voie": [
      29000,
      2200.861678004536
    ],
    "bravo-animation-2-stage-1": [
      33000,
      7152.879818594101
    ],
    "bravo-animation-2-stage-2": [
      42000,
      6702.721088435375
    ],
    "bravo-animation-2": [
      50000,
      37465.034013605444
    ],
    "clobazam": [
      89000,
      5720.408163265304
    ],
    "decouvrez": [
      96000,
      3751.51927437642
    ],
    "dommage-animation-2-stage-1": [
      101000,
      10854.376417233567
    ],
    "dommage-animation-2-stage-2": [
      113000,
      13805.555555555557
    ],
    "dommage-animation-2": [
      128000,
      37164.920634920636
    ],
    "emmm": [
      167000,
      1850.7482993197186
    ],
    "felicitation-mission-1": [
      170000,
      7571.111111111122
    ],
    "grace": [
      179000,
      34864.01360544218
    ],
    "indice-clobazam": [
      215000,
      8803.537414965973
    ],
    "indice-stiripentol": [
      225000,
      6052.448979591844
    ],
    "indice-valproate": [
      233000,
      7903.1746031745915
    ],
    "instruction-1-mission-2": [
      242000,
      16992.925170068018
    ],
    "instruction-2-mission-2": [
      260000,
      17161.201814058928
    ],
    "instruction-mission-1": [
      279000,
      10263.083900226775
    ],
    "irritabilite-appetit": [
      291000,
      5152.063492063518
    ],
    "irritabilite": [
      298000,
      4551.836734693893
    ],
    "merci": [
      304000,
      5852.358276643998
    ],
    "oui": [
      311000,
      2551.043083900254
    ],
    "prochaine-etape": [
      315000,
      20694.376417233572
    ],
    "quite-animation": [
      337000,
      3028.4580498866376
    ],
    "stiripentol": [
      342000,
      6393.37868480726
    ],
    "valproate": [
      350000,
      6225.147392290239
    ]
  }

  export const audioSprites_en =  {
    "appetit": [
      0,
      4063.605442176871
    ],
    "bonjour": [
      6000,
      16993.1746031746
    ],
    "bonne-voie": [
      24000,
      1577.1428571428564
    ],
    "bravo-animation-2-stage-1": [
      27000,
      5868.049886621314
    ],
    "bravo-animation-2-stage-2": [
      34000,
      5342.335600907028
    ],
    "bravo-animation-2": [
      41000,
      36884.85260770975
    ],
    "clobazam": [
      79000,
      5285.4875283446745
    ],
    "decouvrez": [
      86000,
      3751.020408163271
    ],
    "dommage-animation-2-stage-1": [
      91000,
      3864.671201814062
    ],
    "dommage-animation-2-stage-2": [
      96000,
      13213.764172335601
    ],
    "dommage-animation-2": [
      111000,
      36003.92290249434
    ],
    "emmm": [
      149000,
      1719.2290249433029
    ],
    "felicitation-mission-1": [
      152000,
      7715.147392290248
    ],
    "grace": [
      161000,
      33929.50113378686
    ],
    "indice-clobazam": [
      196000,
      8865.986394557807
    ],
    "indice-stiripentol": [
      206000,
      6578.480725623593
    ],
    "indice-valproate": [
      214000,
      9064.92063492064
    ],
    "instruction-1-mission-2": [
      225000,
      14705.646258503406
    ],
    "instruction-2-mission-2": [
      241000,
      17277.34693877551
    ],
    "instruction-mission-1": [
      260000,
      8851.814058956905
    ],
    "irritabilite-appetit": [
      270000,
      4631.927437641707
    ],
    "irritabilite": [
      276000,
      4063.605442176879
    ],
    "merci": [
      282000,
      4884.353741496625
    ],
    "oui": [
      288000,
      2060.2267573696054
    ],
    "prochaine-etape": [
      292000,
      19849.04761904761
    ],
    "quite-animation": [
      313000,
      3140.725623582739
    ],
    "stiripentol": [
      318000,
      5285.4875283446745
    ],
    "valproate": [
      325000,
      5285.4875283446745
    ]
  }

  export const AUDIO_SPRITE_NAMES =  {
    "appetit":  "appetit",
    "bonjour": "bonjour",
    "bonne-voie": "bonne-voie",
    "bravo-animation-2-stage-1": "bravo-animation-2-stage-1",
    "bravo-animation-2-stage-2": "bravo-animation-2-stage-2",
    "bravo-animation-2": "bravo-animation-2",
    "clobazam": "clobazam",
    "decouvrez": "decouvrez",
    "dommage-animation-2-stage-1": "dommage-animation-2-stage-1",
    "dommage-animation-2-stage-2": "dommage-animation-2-stage-2",
    "dommage-animation-2": "dommage-animation-2",
    "emmm": "emmm",
    "felicitation-mission-1": "felicitation-mission-1",
    "grace": "grace",
    "indice-clobazam": "indice-clobazam",
    "indice-stiripentol": "indice-stiripentol",
    "indice-valproate": "indice-valproate",
    "instruction-1-mission-2": "instruction-1-mission-2",
    "instruction-2-mission-2": "instruction-2-mission-2",
    "instruction-mission-1": "instruction-mission-1",
    "irritabilite-appetit": "irritabilite-appetit",
    "irritabilite": "irritabilite",
    "merci": "merci",
    "oui": "oui",
    "prochaine-etape": "prochaine-etape",
    "quite-animation": "quite-animation",
    "stiripentol": "stiripentol",
    "valproate": "valproate",
  };