import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import "../assets/styles/App.scss";
import "../assets/styles/font.css";
import "../assets/styles/splashscreen.scss";
import "../assets/styles/guitarHero.scss";

import "../assets/styles/intro.css"
import "../containers/animManager/animation/animation.scss";
import "../assets/styles/calibration.css"
import SceneManager from "../containers/sceneManager/SceneManager";
import { ImageLoader } from '../containers/imageManager';

import { flexFontStyle } from '../assets/styles/styles';
import RefWidget from '../widgets/button/RefWidget';
import HomeWidget from '../widgets/button/HomeWidget';
import TargetWidget from '../widgets/button/targetWidget';
import { LangContext, LangProvider } from '../translation/context_lang';
import { musicAmbiance } from '../assets/sounds/howler';
import { MUSIC_SPRITE_NAMES } from '../assets/sounds/music';


function App() {
  const [loader, setLoader] = useState(false);
  const scenes = useSelector((state) => state.SceneManager.scenes);

  useEffect(() => {
      musicAmbiance.stop();
      let spriteId = musicAmbiance.play(MUSIC_SPRITE_NAMES.Background);
      return () => {
        musicAmbiance.stop(spriteId);
      }
  },[])
  useEffect(() => {
    flexFontStyle()
  }, [scenes])

  return (
    <div className="App">
      {/* <SoundManager/> */}
      <div className="canvasContainer">

        <LangProvider>
        <div className="canvas" id="canvas">
            
            {loader && <SceneManager />}
            <RefWidget />
            <HomeWidget />
            <TargetWidget/>
            {!loader && <ImageLoader onComplete={() => setLoader(true)} />}
            


        <div id="modal-canvas"></div>

        </div>

        </LangProvider>
      </div>
    </div>
  );
}

export default App;
