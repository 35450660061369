export function onClickDelayed(ms = 100) {
    let canClick = true ;

    return (cb = () => {}) => {
        if(canClick) {
            canClick =  false ;
            cb();
            setTimeout(() => {
                canClick = true
            },ms)
        }
    }

}