import {
    CREATE_PROFILE,
    LOAD_PROFILE,
    SAVE_PROFILE,
    SELECT_PROFILE,
    LOAD_SELECTED_PROFILE
} from "../constants/profileManager";
import { v4 as uuidv4 } from 'uuid';
const profileInitial = {
    score : 100 ,
}
const initialState = {
    currentProfile : "",
    profiles : localStorage.getItem("profiles") ? JSON.parse(localStorage.getItem("profiles")) : [],
    profile : null
};

// use concat, slice, or the spread operator for arrays
// use Object.assign or object spread of objects
function getProfile(id) {
    return JSON.parse(localStorage.getItem(id))
}
function setLocalStorage(id,profile) {
    localStorage.setItem(id,JSON.stringify(profile)) ;
}
function profileManagerRootReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_PROFILE :
            // generate new id  / add new profile to profiles with id / save the new profile to local storage 
            let id = uuidv4();
            let profiles = state.profiles.concat(id) ;
            setLocalStorage("profiles",profiles);
            setLocalStorage(id,profileInitial);
            return Object.assign({},state,{ profiles}) ;
        case SELECT_PROFILE : 
            return Object.assign({},state,{currentProfile : action.id})
        case LOAD_SELECTED_PROFILE :
            // get profile from local storage to store
            return Object.assign({}, state, {profile : {...getProfile(state.currentProfile)}})
        case LOAD_PROFILE : 
            return Object.assign({}, state, {currentProfile : action.id,profile : {...getProfile(action.id)}})
        case SAVE_PROFILE:
            // save profile to local storage and store
            let updatedProfile = Object.assign({}, state , { profile : {...action.payload}}).profile;
            setLocalStorage(state.currentProfile, updatedProfile) ; 
            return Object.assign({}, state, { profile : {...action.payload}});
        default:
            return state;
    }
}

export default profileManagerRootReducer;