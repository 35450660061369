import {
    MUSIC_VOLUME,
    SOUND_EFFECT_VOLUME,
    ON_MUTE,
  } from "../constants/soundManager";

  const initialState={
     volumes:{
        volumeMusic :0.2,
        volumeSoundEffect :0.2,
     },
     onMute:false,
      
  }

  function soundManagerRootReducer(state = initialState, action) {
    switch (action.type) {
        case MUSIC_VOLUME:
            
            return {...state,
                volumeMusic:action.payload 

            }

            
        case SOUND_EFFECT_VOLUME:
           
            return {...state,
                volumeSoundEffect:action.payload 

            }
        case ON_MUTE:  
            return {...state,
                onMute:action.payload, 

            }

      
        default:
            return state;
    }
}

export default soundManagerRootReducer;