import { useState } from "react";
import { ref } from "../../assets/Images";
import Modal from "../../components/modal/Modal";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { ColorfulTranslation } from "../../components/ColorfulTranslation";
import { soundFx } from "../../assets/sounds/howler";
import { FX_SPRITE_NAMES } from "../../assets/sounds/fx";

function RefWidget({
    scale = 1,
    width = 6,
    height = 6,
    left,
    top,
    className,
    style,
    children
}) {
    const [showRef, setshowRef] = useState(false);
    function click() {
        soundFx.play(FX_SPRITE_NAMES.button_on_off_064);
        
        setshowRef((sr) => !sr);
    }
    return (
        <>
            <Button
                onClick={click}
                src={ref}
                ratio={`${width}:${height}`}
                scale={scale}
                left={"2%"}
                top={"70%"}
            ></Button>

            <Modal
                setShow={setshowRef}
                xButon={true}
                isOpen={showRef}
                outSideClose={true}
            >
                <RefContent/>
                <div></div>
            </Modal>
        </>
    );
}


export const RefContent = () => {
    const { t } = useTranslation (undefined,{keyPrefix : "general.popup_ref"});
    return <div className="content ref">
    <h1 className="title">{t("title")}</h1>
    <section>
        <ol>
            { t("text_1") !== ""  && <li>
                <ColorfulTranslation t={t} i18nKey={"text_1"}/>
 
            </li> }
            { t("text_2") !== ""  && <li>
                <ColorfulTranslation t={t} i18nKey={"text_2"}/>
 
            </li> }
            { t("text_3") !== ""  && <li>
                <ColorfulTranslation t={t} i18nKey={"text_3"}/>
 
            </li> }
            { t("text_4") !== ""  && <li>
                <ColorfulTranslation t={t} i18nKey={"text_4"}/>
 
            </li> }
            { t("text_5") !== ""  && <li>
                <ColorfulTranslation t={t} i18nKey={"text_5"}/>
 
            </li> }
            { t("text_6") !== ""  && <li>
                <ColorfulTranslation t={t} i18nKey={"text_6"}/>
 
            </li> }
        </ol>
    </section>
</div>
}
export default RefWidget;
