import React, { memo } from "react";

const Clobazam = (props) => {
  return (
    <svg
      id="Dark-Clobazam"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 113.19 435.77"
      {...props}
    >
      <defs>
        <linearGradient
          id="linear-gradient-Clobazam"
          x1={6.26}
          y1={171.68}
          x2={132.79}
          y2={250.48}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#ffe340" />
          <stop offset={1} stopColor="#ff6700" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-Clobazam-2"
          x1={52.45}
          y1={23.57}
          x2={113.19}
          y2={23.57}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#1ad698" />
          <stop offset={1} stopColor="#68d2d1" />
        </linearGradient>
        <style>{".cls-2,.cls-3{fill-rule:evenodd}.cls-3{fill:#fff}"}</style>
      </defs>
      <path
        d="M69.8 353.21s-7.56 8-7.56 18.89c0 8.23 8 16.23 8 16.23s9.33-9.34 8.45-18.45c-.93-9.51-8.89-16.67-8.89-16.67Z"
        style={{
          fillRule: "evenodd",
          fill: "#ffe340",
        }}
      />
      <g id="Table_chimie" data-name="Table chimie">
        <path
          className="cls-2"
          d="M16.92 71.41c0-3.92 1.49-10.84 7.77-10.84 6.13 0 7.84 5.94 7.87 10.26.07 9.43.15 306.86.15 361.63a33.26 33.26 0 0 0 10.93.39c-.2-.63-.36-1.24-.57-1.82q-3.32-8.94-6.65-17.87c-.8-2.14-.71-2.47 1-3.93q9.09-7.71 18.17-15.44a4.34 4.34 0 0 1 3-1.11q12.81.06 25.61 0a4.35 4.35 0 0 1 3 1.13c5.31 4.56 10.67 9.08 16 13.62l2.29 1.94-11.85 26.4H16.83c-.01-55.12.08-355.12.09-364.36Zm11.43 358.8V136.52c0-3.2-.34-12.65-2.91-16.56-1.09-1.67-2.1-2.89-3.18-3.28a3.41 3.41 0 0 0-1-.07v313.6Zm29.22-4.15a2.88 2.88 0 0 1 2.65-1.5A162.09 162.09 0 0 1 81 423.05h7.58a2.42 2.42 0 0 0 2.57-1.83c-1.21-.08-2.26-.14-3.31-.23-4.16-.35-8.32-.68-12.48-1.07a10.66 10.66 0 0 1-1.92-.55c.26-1.13 1-1.16 1.69-1.25a147.61 147.61 0 0 1 18-1.07c1.06 0 2.1.09 2.84-1 .59-.82 1.33-1.52 2.17-2.46H41.19l6.29 16.94c.47.05.82.12 1.16.12h12.72a9.59 9.59 0 0 0 1.92-.23q5.77-1.23 11.55-2.5c.47-.1.92-.27 1.37-.41a17.94 17.94 0 0 0-3.39-.27c-4.35-.21-8.7-.42-13-.68a12.13 12.13 0 0 1-2.24-.5ZM21.19 101.41c2.37 0-1.73-.08.37 0a4.17 4.17 0 0 0 4-2.15 17.26 17.26 0 0 0 2.81-9V71.74c0-.47.24-7.2-3.65-7.2-3.57 0-3.48 6.19-3.48 7.29v27.52ZM44.38 409l.13.3h28.32c-1.06-4.15-2.08-8.18-3.12-12.29H59.03a2.54 2.54 0 0 0-1.35.69c-2.3 1.91-4.58 3.86-6.86 5.8Zm38.55-12.15-.24.44 8.39 12h7.49c-.29-.33-.41-.51-.57-.65-4.34-3.7-8.67-7.41-13.06-11.06a6.46 6.46 0 0 0-2.01-.76Z"
        />
        <path
          className="cls-3"
          d="M82.93 396.82a6.46 6.46 0 0 1 2 .71c4.39 3.65 8.72 7.36 13.06 11.06.16.14.28.32.57.65h-7.48l-8.39-12ZM57.57 426.06a12.13 12.13 0 0 0 2.2.57c4.34.26 8.69.47 13 .68a17.94 17.94 0 0 1 3.39.27c-.45.14-.9.31-1.37.41q-5.77 1.26-11.55 2.5a9.59 9.59 0 0 1-1.92.23H48.6c-.34 0-.69-.07-1.16-.12l-6.29-16.94h56.9c-.84.94-1.58 1.64-2.17 2.46-.74 1.05-1.78.95-2.84 1a147.61 147.61 0 0 0-18 1.07c-.71.09-1.43.12-1.69 1.25a10.66 10.66 0 0 0 1.92.55c4.16.39 8.32.72 12.48 1.07 1 .09 2.1.15 3.31.23a2.42 2.42 0 0 1-2.57 1.83c-2.52-.08-5-.06-7.58 0a162.09 162.09 0 0 0-20.77 1.51 2.88 2.88 0 0 0-2.57 1.43ZM44.38 409l6.44-5.47c2.28-1.94 4.56-3.89 6.86-5.8A2.54 2.54 0 0 1 59 397c3.52-.06 7 0 10.68 0 1 4.11 2.06 8.14 3.12 12.29H44.51Z"
        />
        <path
          className="cls-2"
          d="M60.09 371a25.55 25.55 0 0 1 7.74-18.48c.33-.33.68-.65 1-.95 1.3-1.05 2.15-1.05 3.43.07a24.36 24.36 0 0 1 5.17 6.22 25.54 25.54 0 0 1-4.71 31.78c-1.77 1.64-2.62 1.68-4.36.05A25.36 25.36 0 0 1 60.09 371Zm10.59-15.12C65 361.05 63 371 65.33 376c1.08-1.66 2.26-3.21 3.15-4.91 1.71-3.27 3.26-6.64 4.88-10a1.65 1.65 0 0 0-.15-2c-.84-1-1.65-2.09-2.53-3.21Z"
        />
      </g>
      <path
        className="cls-2"
        d="M7.55 114.39H2.43c-1.71 0-2.36-.79-2.43-2.27q-.08-4.23 0-8.48c0-1.45.85-2.18 2.4-2.22h94.72c1.55 0 2.37.77 2.4 2.22q.07 4.24 0 8.48c0 1.48-.83 2.2-2.39 2.25s-3.42 0-5.12 0Z"
      />
      <path
        className="cls-3"
        d="M4.35 110v-4c1.27-.39 88.64-.49 90.74-.1v4.1Z"
      />
      <path
        d="M56.16 91.54h28.45v232.24A14.22 14.22 0 0 1 70.39 338a14.22 14.22 0 0 1-14.22-14.22V91.54Z"
        style={{
          fill: "url(#linear-gradient-Clobazam)",
          clipPath: props.clipPath(),
          transition: "all 0.5s ease 0s",
        }}
      />
      <g id="Table_chimie-2" data-name="Table chimie">
        <path
          className="cls-2"
          d="M86.65 321.57a25 25 0 0 1-.76 6.28 15.8 15.8 0 0 1-16.24 11.77 16 16 0 0 1-15.13-13.43 25.75 25.75 0 0 1-.36-4.39V93.08c-.8-.19-1.48-.32-2.15-.52a8.59 8.59 0 0 1 .67-16.65 11.67 11.67 0 0 1 2.28-.22h30.89A8.63 8.63 0 0 1 88.7 92.6c-.62.17-1.24.3-1.94.46 0 2.72-.05 211.38-.11 228.51ZM70.19 88.64h16.23a4.31 4.31 0 0 0 .09-8.64H55.28a11.09 11.09 0 0 0-1.41.06 4.18 4.18 0 0 0-3.72 4 4.24 4.24 0 0 0 4.52 4.52c5.18.08 10.33.06 15.52.06ZM82.25 101a25.18 25.18 0 0 0-2.71-2.47c-3.47-2.35-7.11-4.34-11.4-4.78a62.31 62.31 0 0 0-9.66 0c0 2.6-.1 210.5-.08 227.62a22.87 22.87 0 0 0 .36 4.21 11.83 11.83 0 0 0 23.17.63 20.34 20.34 0 0 0 .46-4.54c.04-12.37-.14-220.67-.14-220.67Z"
        />
        <path
          className="cls-3"
          d="M70.19 88.64H54.67a4.24 4.24 0 0 1-4.52-4.52 4.18 4.18 0 0 1 3.72-4 11.09 11.09 0 0 1 1.41-.12h31.23a4.31 4.31 0 0 1-.09 8.61c-1.93.05-3.88 0-5.82 0Z"
        />
      </g>
      <path d="M89 4.84c-8.12 0-16.23.42-24.34.8-4.06.19-8.12.35-12.17.37h-1.37a8.45 8.45 0 0 0-1.21.27A6.13 6.13 0 0 0 45.76 12l-.42 12.17c-.15 4.06-.3 8.11-.35 12.17s0 8.11 0 12.17a7.65 7.65 0 0 0 .4 2.82 6.66 6.66 0 0 0 6.4 4.36c8.12-.29 16.23-.51 24.34-.59l12.2-.1H89.67a5.75 5.75 0 0 0 1.16-.27 5.66 5.66 0 0 0 2-1.2 5.75 5.75 0 0 0 1.87-4.22c.07-8.11.16-16.22.61-24.34.19-4.05.43-8.11.58-12.17A7 7 0 0 0 89 4.84Zm0-.3a7.57 7.57 0 0 1 5.56 2.4 7.43 7.43 0 0 1 1.68 2.66 9.43 9.43 0 0 1 .5 3.09c.15 4.06.39 8.12.58 12.17.46 8.12.55 16.23.61 24.34a8.92 8.92 0 0 1-6.09 8.45A8.59 8.59 0 0 1 90 58c-.3 0-.61 0-.92.05h-.78l-12.17-.15q-12.13-.1-24.31-.56a8.07 8.07 0 0 1-5.88-2.78 8.31 8.31 0 0 1-1.94-6q.09-6.09 0-12.17c-.05-4.06-.19-8.11-.35-12.17L43.26 12A8.45 8.45 0 0 1 46 5.62a8.67 8.67 0 0 1 3.08-1.82 7.57 7.57 0 0 1 1.76-.38 8.69 8.69 0 0 1 .89 0h.78c4.05 0 8.11.18 12.17.37 8.11.34 16.22.8 24.32.75Z" />
      <path
        d="M53.87 29.49c3.35 2.53 4.63 2.88 7.43 6.7 4.58 6.25 3.9 8.83 5.26 10s7.58 2.15 11.83-3.28S89.25 22.5 97.74 13.5s12.45-11.6 15-12.95-5.53-1.07-21.4 11.54C80.31 20.86 72.5 33 72.5 33a29.06 29.06 0 0 0-7.13-11c-4.92-4.48-17.06 3.29-11.5 7.49Z"
        className={`checkMark ${props.status === false ? "visible" : ""}`}
        style={{
          fill: "url(#linear-gradient-Clobazam-2)",
        }}
      />
    </svg>
  );
};

export default memo(Clobazam);
