import React, { useEffect, useCallback } from "react";

import Validate from "./Validate";
import { useDispatch } from "react-redux";
import { setAttemptsNbrAction, setCurrentAttemptsAction, setDoctorAction, setSolutionValueAction } from "../../redux/actions/calibrationManager";
import { checkAnswers, doctorMessage } from "./utils/validators";
import Button from "../../widgets/button/Button";
import { useTranslation } from "react-i18next";


const ValidateCalibration = ({solutions,attemptsNbr,currentAttempts,stage,onSubmit=() => {}}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(undefined,{keyPrefix : "calibration.gui"});
  async function validate (){
    let nextAttemptsNbr = attemptsNbr - 1;
    let nextCurrentAttempts = currentAttempts + 1;
    if (attemptsNbr > 0) {
      let [result,win] = checkAnswers(solutions);
      let doctor = doctorMessage(result,stage);
      for (let index = 0; index < result.length; index++) {
        const solutionChecked = result[index];
         
         dispatch(setSolutionValueAction(solutionChecked.id,
          {
            submittedValue : solutionChecked.currentValue,
            lastSubmitValue : solutionChecked.submittedValue  ,
            status : solutionChecked.result,
          }
        ));
      }
      
      dispatch(setDoctorAction(doctor)) 
      dispatch(setAttemptsNbrAction(nextAttemptsNbr));
      dispatch(setCurrentAttemptsAction(nextCurrentAttempts));
      let goNext = ((nextCurrentAttempts === 2 && stage === 0) || (nextCurrentAttempts === 5 && stage === 1))
      onSubmit(win,goNext);  
      
    }
  };









  return (
    <Button top={"90%"} left={"43%"} className="flexFont primary" ratio={"20:6"} scale={1} onClick={validate}>
      <span style={{fontSize: "1.3em"}}>{t("validate")}</span>
    </Button>

    // <div className="validate-calibration">
    //   <Validate  />
    // </div>
  );
};

export default ValidateCalibration;
