export function flexFontStyle() {
  function flexFont() {
    var divs = document.getElementsByClassName("flexFont");
    for (var i = 0; i < divs.length; i++) {
      let ratio = 0.05;
      var relFontsize = divs[i].offsetWidth * ratio;

      divs[i].style.fontSize = relFontsize + "px";
    }
  }
  flexFont();
  window.onload = function (event) {
    flexFont();
  };
  window.onresize = function (event) {
    flexFont();
  };
  
}

export function initialFlexFont() {
  // a little delay to let the component mount 
  setTimeout(() => {
    var divs = document.getElementsByClassName("flexFont");
    for (var i = 0; i < divs.length; i++) {
      let ratio = 0.05;
      var relFontsize = divs[i].offsetWidth * ratio;
      divs[i].style.fontSize = relFontsize + 'px';
    }
  },0)

}
export const clickableStyle = {
  boxShadow:
    "0 0 2px #fff, 0 0 10px #fff, 0 0 20px #0ba9ca, 0 0 30px #0ba9ca,\n    0 0 40px #0ba9ca, 0 0 50px #0ba9ca",
  WebkitAnimation: "blink 0.7s infinite alternate",
  animation: "blink 0.7s infinite alternate",
};
export const disabledStyle = {
  pointerEvents: "none",
};
