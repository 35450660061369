import {useTranslation } from 'react-i18next'
import { ColorfulTranslation } from '../../ColorfulTranslation';
import { useVoice } from '../../../utils/hooks/useVoice';

export function ValproateContent({}) {
    const { t } = useTranslation(undefined,{keyPrefix : "calibration.valproate"});
    useVoice("valproate",true)
    return <section className="content center-text">
        <h1 className="title"><ColorfulTranslation t={t} i18nKey={"info_title"}/></h1>
        <section>
            <p><ColorfulTranslation t={t} i18nKey={"info_content"}/></p>
        </section>
    </section>
}