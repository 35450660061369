import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {home} from "../../assets/Images" ;
import Modal from "../../components/modal/Modal";
import { removeAllAndAdd } from "../../redux/actions/sceneManager";
import { CONST_SCREENS } from "../../Screens";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { audioAmbiance, soundFx } from "../../assets/sounds/howler";
import { FX_SPRITE_NAMES } from "../../assets/sounds/fx";
import { AUDIO_SPRITE_NAMES } from "../../assets/sounds/audio";
import { useVoice } from "../../utils/hooks/useVoice";


function HomeWidget ({scale = 1,width = 6,height = 6,left,top,className,style}) {
    const dispatch = useDispatch()
    const [showRef,setshowRef] = useState(false);
    const { t } = useTranslation (undefined,{keyPrefix : "general.popup_home"});
    function click() {
        soundFx.play(FX_SPRITE_NAMES.button_on_off_064);
        setshowRef((sr) => !sr)
    }
    useVoice(AUDIO_SPRITE_NAMES["quite-animation"],showRef);
    return (
    <>
        <Button onClick={click} src={home} ratio={`${width}:${height}`} scale={scale} left={"2%"} top ={"90%"}></Button>

        <Modal setShow={setshowRef} isOpen={showRef}>
            <div className="one-line-popup-content content">{t("text")}</div>
            <div>
            <Button top={"75.5%"} left={"30.5%"} className="flexFont primary" ratio={"18:7"} scale={1} onClick={() => setshowRef(false)}>{t("no_button")}</Button>
            <Button top={"75.5%"} left={"50.5%"} className="flexFont primary" ratio={"18:7"} scale={1} onClick={() => {dispatch(removeAllAndAdd(CONST_SCREENS.SPLASHSCREEN));setshowRef(false)}}>{t("yes_button")}</Button>
            </div>
        </Modal>
    </>
    ) 
}

export default HomeWidget ;