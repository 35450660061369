import React from "react";
import scenes from "../../Screens"

const scene =  props => {
  if (typeof scenes[props.component] !== "undefined") {
    return React.createElement(scenes[props.component], {
      key: props._uid,
      props
    });
  }
  return React.createElement(
    () => <div>The component {props.component} has not been created yet.</div>,
    { key: props._uid }
  );
};

export default scene ;