import { useState,useContext } from "react";
import {infoClobazam, infoStiripentol,infoStiripentol_en} from "../../assets/Images" ;
import Modal from "../../components/modal/Modal";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { soundFx } from "../../assets/sounds/howler";
import { FX_SPRITE_NAMES } from "../../assets/sounds/fx";
import { LangContext } from "../../translation/context_lang";

function StiripentolButton ({scale = 1,width = 6,height = 6,children,left,top,className,style}) {
    const [showRef,setshowRef] = useState(false);
    const { t } = useTranslation(undefined,{keyPrefix : "general"});
    const {value : {isLangSet,lang}} = useContext(LangContext);
    function click() {
        soundFx.play(FX_SPRITE_NAMES.button_on_off_064);
        setshowRef((sr) => !sr)
    }
    return (
    <>
        <Button onClick={click} top={"75%"} left={"74%"} src={lang === "fr" ? infoStiripentol : infoStiripentol_en} scale={1} ratio={"16:5"}></Button>
        <Modal setShow={setshowRef} isOpen={showRef}  outSideClose = {true}>
            <div>{children}</div>
            <div>
            <Button top={"90%"} left={"43%"} className="flexFont primary" ratio={"15:6"} scale={1} onClick={() => setshowRef(false)}>{t("popups.compris")}</Button>
            </div>
        </Modal>
    </>
    ) 
}

export default StiripentolButton ;