import { useLayoutEffect,useEffect,useRef } from "react";
import { background_popup } from "../../assets/Images";
import { initialFlexFont } from "../../assets/styles/styles";
import "./modalStyles.css";
import ReactPortal from "./ReactPortal.js";
import { soundFx } from "../../assets/sounds/howler";
import { FX_SPRITE_NAMES } from "../../assets/sounds/fx";
import { usePreviousValue } from "../../utils/hooks/usePreviousValue";

function Modal({defaultSound = true,style={},className="",children, isOpen, xButon,outSideClose = false,setShow=() => {} }) {
  const prevOpen = usePreviousValue(isOpen);
  useEffect(() => {
    initialFlexFont() ;
  },[isOpen])

  useEffect(()=> {
    defaultSound && prevOpen && !isOpen && soundFx.play(FX_SPRITE_NAMES.button_on_off_064);
  },[isOpen,prevOpen,defaultSound])


  if (!isOpen) return null;
  
  const [content,out] = children ;
  return (
    <ReactPortal wrapperId="modal-canvas">
    <div className={`${className} modal fade-in flexFont`}>
      <div className="modal-container">
        <img className="background-popup" onClick={() => outSideClose && setShow(false)} src={background_popup} alt=""></img>

        {out}
      <div className="modal-content" style={style}>
        <div className="the-real-modal-content scrollbar-design">
        { xButon && <button onClick={()=> {setShow(false)}} className="close-btn close">
          x
        </button>}  
        {content}
        </div>
      </div>
    </div>
    </div>
    </ReactPortal>
  );
}
export default Modal;