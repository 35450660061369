import { useEffect, useState } from "react";
function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
function Screen({children,component="NONE"}) {
    const [color,setColor] = useState("");
    useEffect(() => {setColor(getRandomColor)},[])
    return <div className="Screen">
        <div style={{}}>
   {/* <div style={{position : "absolute",top: "3px",left : "3px",boxShadow: "#ffffff7a 1px 2px 3px", padding : "4px 14px",background : "#00000099",color : "white"}}>{component}</div> */}
        {children}
    </div>
  </div>
}
export default Screen ;