export const fxSprites =  {
    "tone1": [
      0,
      95.32879818594105
    ],
    "Button_Click_01": [
      1999.9999999999998,
      1044.8979591836737
    ],
    "Button_Click_03": [
      5000,
      1044.8979591836735
    ],
    "button_on_off_064": [
      8000,
      351.04308390022607
    ],
    "FUI_Ease_Into_Position": [
      10000,
      296.66666666666686
    ],
    "FUI_Navigation_Swipe_2": [
      12000,
      405.98639455782325
    ],
    "game_over": [
      14000,
      7183.673469387756
    ],
    "game_upgrade": [
      23000,
      2812.4943310657604
    ],
    "light_switch_019": [
      27000,
      231.79138321995296
    ],
    "TM_GLITCH UI_Clicks dry_11": [
      29000,
      63.85487528344669
    ],
    "vintage_radio_button_006": [
      31000,
      293.7641723355995
    ]
};

export const FX_SPRITE_NAMES =  {
  "tone1":  "tone1",
  "Button_Click_01": "Button_Click_01",
  "Button_Click_03": "Button_Click_03",
  "button_on_off_064": "button_on_off_064",
  "FUI_Ease_Into_Position": "FUI_Ease_Into_Position",
  "FUI_Navigation_Swipe_2": "FUI_Navigation_Swipe_2",
  "game_over": "game_over",
  "game_upgrade": "game_upgrade",
  "light_switch_019": "light_switch_019",
  "TM_GLITCH UI_Clicks dry_11": "TM_GLITCH UI_Clicks dry_11",
  "vintage_radio_button_006": "vintage_radio_button_006",
};
