import Screen from "./Screen";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { CONST_SCREENS } from "../Screens";
import { addScene, deleteScene, removeAllAndAdd } from "../redux/actions/sceneManager";
import { headset, intro_background } from "../assets/Images";
import Button from "../widgets/button/Button";
import { flexFontStyle } from "../assets/styles/styles";
import { TargetContent } from "../widgets/button/targetWidget";
import Modal from "../components/modal/Modal";
import { ContentTutorial } from "./guitarHeroTutorial";
import { Doctor } from "../components/Doctor";
import { useTranslation } from "react-i18next";
import { ColorfulTranslation } from "../components/ColorfulTranslation";
import { soundFx } from "../assets/sounds/howler";
import { FX_SPRITE_NAMES } from "../assets/sounds/fx";
import { useVoice } from "../utils/hooks/useVoice";
import { AUDIO_SPRITE_NAMES } from "../assets/sounds/audio";

const Intro = (props) => {
  const dispatch = useDispatch();
  // const [background, setBackground] = useState("background_stars_BG");
  const [index, setIndex] = useState(0);
  const [videoPopUp, setVideoPopUp] = useState(false);
  const { t } = useTranslation(undefined,{keyPrefix : "introduction"});
  function handleIndex() {
    soundFx.play(FX_SPRITE_NAMES["TM_GLITCH UI_Clicks dry_11"]);
    if (index < 2) setIndex((i) => i + 1);
    else setVideoPopUp(true)
  }

  function nextScene() {
    setVideoPopUp(false)
    soundFx.play(FX_SPRITE_NAMES.FUI_Ease_Into_Position);
    dispatch(removeAllAndAdd(CONST_SCREENS["GUITAR_HERO"]))
  }


  useEffect(() => {
    dispatch(deleteScene(CONST_SCREENS["SPLASHSCREEN"]));
  }, []);

  useEffect(() => {
    flexFontStyle();
  }, [index]);
  return (
    <Screen component={props.props.component}>
      {index !== 1 && <Background />}
      {index === 0 && <Content1 />}
      {index === 1 && <TargetContent className="standalone" />}
      {index === 2 && <Content3 />}

      <Button onClick={handleIndex} top={"83%"} left={"42.5%"} className="flexFont primary" ratio={"15:6"} scale={1} >
        <span style={{ fontSize: "2em" }}>{t("gui.next")}</span>
      </Button>


      {index === 0 && <Button top={"89%"} left={"28%"} className="flexFont" ratio={"16:9"} width={5} >
        <span style={{ fontSize: "0.5em" }}>{t("screen_1.footer")}</span>
      </Button>}


      <Modal style={{ height: "74.9%", maxHeight: "75%", width: "75%", boxSizing: "content-box" }} defaultSound = {false} setShow={setVideoPopUp} isOpen={videoPopUp} outSideClose={true}>
        <ContentTutorial />


        <div>
          <Button top={"90%"} left={"43%"} className="flexFont primary" ratio={"15:6"} scale={1} onClick={nextScene}><span style={{ fontSize: "1.8em" }}>{t("gui.compris")}</span></Button>
        </div>
      </Modal>

    </Screen>
  );
};

function Content1() {
  const { t } = useTranslation(undefined,{keyPrefix : "introduction.screen_1"});

  return <div className="intro__block flexFont">
    <h5 style={{ padding: "0.3em 1em" }} className="intro__block__center font-size-03">
      <ColorfulTranslation t={t} i18nKey={"text_1"}/>
    </h5>
    <img width={"30%"} className="intro__block__center" src={headset} alt="" />
    <p style={{ padding: "1em 3.8em" }} className="intro__block__center font-size-03">
      <ColorfulTranslation t={t} i18nKey={"text_2"}/>
    </p>
  </div>
}

function Content3() {
  const { t } = useTranslation(undefined,{keyPrefix : "introduction.screen_2"});
  useVoice(AUDIO_SPRITE_NAMES["bonjour"],true);
  return <div style={{paddingTop : "9%"}} className="intro__block flexFont middle">
      <h5 style={{ padding: "1em 1em" }} className="intro__block__center font-size-03">
        <ColorfulTranslation t={t} i18nKey={"text_1"}/>

      </h5>
      <h5 style={{ padding: "1em 1em" }} className="intro__block__center font-size-03">
        <ColorfulTranslation t={t} i18nKey={"text_2"}/>
      </h5>
      <h5 style={{ padding: "1em 1em" }} className="intro__block__center font-size-03">
        <ColorfulTranslation t={t} i18nKey={"text_3"}/>
      </h5>
  </div>
}


function Background() {
  return (
    <div className="screen-background">
      <div>
        
        <img className="img" alt="" height={"100%"} width={"100%"} src={intro_background} />
        {/* TODO : ADD logos images (biocodex,biacomit) to the top left top right */}

        <Doctor />
      </div>
    </div>
  );
}


export default Intro;
