import Screen from "./Screen";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../widgets/button/Button";
import { useTranslation } from "react-i18next";
import { ColorfulTranslation } from "../components/ColorfulTranslation";
import { intro_background, solution_images,solution_images_en } from "../assets/Images";
import { Doctor } from "../components/Doctor";
import { molecule_bg } from "../assets/Images";
import { flexFontStyle } from "../assets/styles/styles";
import { happy_faces } from "../assets/Images";
import { removeAllAndAdd } from "../redux/actions/sceneManager";
import { CONST_SCREENS } from ".";
import { soundFx } from "../assets/sounds/howler";
import { FX_SPRITE_NAMES } from "../assets/sounds/fx";
import { useVoice } from "../utils/hooks/useVoice";
import { AUDIO_SPRITE_NAMES } from "../assets/sounds/audio";
import { LangContext } from "../translation/context_lang";
//
const AfterGuitarHero = (props) => {
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const {value : {isLangSet,lang}} = useContext(LangContext);
  const { t } = useTranslation(undefined,{keyPrefix : "guitar_hero.result"});
  useVoice(AUDIO_SPRITE_NAMES["felicitation-mission-1"],index === 0);
  useVoice(AUDIO_SPRITE_NAMES["prochaine-etape"],index === 1);
  
  useEffect(() => {
    flexFontStyle();
  }, [index]);
  useEffect(() => {
    soundFx.play(FX_SPRITE_NAMES.game_upgrade);
  },[])
  function handleIndex() {
    soundFx.play(FX_SPRITE_NAMES["TM_GLITCH UI_Clicks dry_11"]);
    if (index < 1) setIndex((i) => i + 1);
    else dispatch(removeAllAndAdd(CONST_SCREENS["CALIBRATION_SCREEN"]));
  }
  
  return (
    <Screen component={props.props.component}>
      {/* The screen is relative and here we can add what we want here */}
      
      {index === 0 && <Background1/> }
      {index === 0 && <Content1 lang={lang}/>  }
      {index === 1 && <Background2 /> }
      {index === 1 && <Content2 lang={lang}/> }
      <Button onClick={handleIndex} top={"83%"} left={"42.5%"} className="flexFont primary" ratio={"15:6"} scale={1} >
        <span style={{ fontSize: "2em" }}>{t("next")}</span>
      </Button>
    </Screen>
  );
};
function Content1({lang = "fr"}) {
    const { t } = useTranslation(undefined,{keyPrefix : "guitar_hero.result"});
  
    return <div className="intro__block container flexFont fade-in">
        <h1 style={{ padding: "0.5em 1em",paddingBottom :"0",fontSize :"0.5em" }} className="intro__block__center">
          <ColorfulTranslation t={t} i18nKey={"title"}/>
        </h1>
        <h5 style={{ padding: "0em 1em",paddingTop : "0" }} className="intro__block__center font-size-03">
          <ColorfulTranslation t={t} i18nKey={"text_1"}/>
        </h5>
        <img width={"45%"}  className="intro__block__center" src={happy_faces} alt="" />
        <img width={"65%"}  className="intro__block__center" src={lang === "fr" ? solution_images : solution_images_en} alt="" />

        <h5 style={{ padding: "0.9em 1em" }} className="intro__block__center font-size-03">
          <ColorfulTranslation t={t} i18nKey={"text_2"}/>
        </h5>
    </div>
  }

function Content2({lang}) {
    const { t } = useTranslation(undefined,{keyPrefix : "calibration.page_intro"});
  
    return <div style={{paddingTop : "8%"}} className="intro__block flexFont middle fade-in">
        <h1 style={{ padding: "0.5em 1em",fontSize :"0.6em" }} className="intro__block__center">
          <ColorfulTranslation t={t} i18nKey={"title"}/>
        </h1>

        <img width={"45%"} className="intro__block__center" src={lang === "fr" ? solution_images : solution_images_en} alt="" />

        <h5 style={{ padding: "0.9em 1em" }} className="intro__block__center font-size-03">
          <ColorfulTranslation t={t} i18nKey={"text_1"}/>
        </h5>
        <h5 style={{ padding: "0.9em 1em" }} className="intro__block__center font-size-03">
          <ColorfulTranslation t={t} i18nKey={"text_2"}/>
        </h5>
    </div>
  }




  function Background2() {
    return (
      <div className="screen-background fade-in" >
        <div>
          
          <img className="img" alt="" height={"100%"} width={"100%"} src={intro_background} />
          {/* TODO : ADD logos images (biocodex,biacomit) to the top left top right */}
  
          <Doctor />
        </div>
      </div>
    );
  }


  function Background1() {
    return (
        <div className="screen-background fade-in">
        <div>
          
          <img className="img floating" alt="" height={"100%"} width={"100%"} src={molecule_bg} />
        </div>
      </div> 
    )
  }
export default AfterGuitarHero;
