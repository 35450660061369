import * as React from "react";
const Indicator = ({code,...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 213.19 57.66"
        {...props}
    >
                <rect
                    width={57.66}
                    height={213.19}
                    x={77.77}
                    y={-77.77}
                    rx={28.83}
                    transform="rotate(90 106.595 28.825)"
                />
                <circle cx={183.63} cy={28.83} r={22.26} className={`indicator-circle green ${code === "green" ? "active": ""}`} />
                <circle cx={132.27} cy={28.83} r={22.26} className={`indicator-circle yellow ${code === "yellow" ? "active": ""}`} />
                <circle cx={80.92} cy={28.83} r={22.26} className={`indicator-circle orange ${code === "orange" ? "active": ""}`} />
                <circle cx={29.56} cy={28.83} r={22.26} className={`indicator-circle red ${code === "red" ? "active": ""}`} />
    </svg>
);
export default Indicator;
