import { fxSprites } from './fx';
import fx from './fx.webm';

import { musicSprites } from './music';
import music from "./music.webm";

import { audioSprites_fr,audioSprites_en } from './audio';
import audio from "./audio.webm";
import audio_en from "./audio_en.webm";

export const sounds ={
    fx: "fx", 
    music : "music",
    sound_fr : "sound_fr",
    sound_en : "sound_en",
}

const SOUNDS = {
    [sounds.fx] : {src : fx,sprites : fxSprites },
    [sounds.music] : {src : music,sprites : musicSprites },
    [sounds.sound_fr] : {src : audio,sprites : audioSprites_fr },
    [sounds.sound_en] : {src : audio_en,sprites : audioSprites_en },
}

export default SOUNDS;


