import {
  CHANGE_ORDER_SCENE,
  ADD_SCENE,
  DELETE_SCENE,
  REMOVE_ALL_AND_ADD,
} from "../constants/sceneManager";
import { CONST_SCREENS } from "../../Screens";
import { v4 as uuidv4 } from "uuid";
// const initialState = {
//     scenes: [{
//         _uid: uuidv4(),
//         component: CONST_SCREENS.COMPUTER,
//     }, {
//         _uid: uuidv4(),
//         component: CONST_SCREENS.TESTLABO,
//     }, {
//         _uid: uuidv4(),
//         component: CONST_SCREENS.SPLASHSCREEN,
//     }, ],
// };
const initialState = {
  scenes: [
    {
      _uid: uuidv4(),
      component: CONST_SCREENS.SPLASHSCREEN,
      // component : CONST_SCREENS.CALIBRATION_SCREEN

    },
  ],
};

// use concat, slice, or the spread operator for arrays
// use Object.assign or object spread of objects
function sceneManagerRootReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SCENE:
      if (
        state.scenes.filter(
          (scene) => scene.component === action.payload.component
        ).length !== 0
      )
        return state;
      return Object.assign({}, state, {
        scenes: state.scenes.concat(action.payload), // add the scenes to bottom of the Q
      });

    case DELETE_SCENE:
      return Object.assign({}, state, {
        scenes: state.scenes.filter(
          (scene) => scene.component !== action.payload.component
        ), // delete the scenes from the Q
      });
    case CHANGE_ORDER_SCENE:
      // TODO: get the removed element and add to the bottom
      const removedElement = state.scenes.filter(
        (scene) => scene.component === action.payload.component
      );
      return Object.assign({}, state, {
        scenes: state.scenes
          .filter((scene) => scene.component !== action.payload.component) // delete the scenes from the Q
          .concat(...removedElement), // add to the bottom of the Q
      });

    case REMOVE_ALL_AND_ADD :
      return Object.assign({}, {
        scenes: [action.payload], // add the scenes to bottom of the Q
      });

    default:
      return state;
  }
}

export default sceneManagerRootReducer;
