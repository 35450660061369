import React from "react";
import Solution from "./Solution";

const Solutions = ({ solutions, currentAnswers, setCurrentAnswers }) => {
  return (
    <div className="solutions-container">
      {solutions.map((solution, i) => {
        return (
          <Solution
            key={solution.id}
            solution={solution}
            
          />
        );
      })}
    </div>
  );
};

export default Solutions;
