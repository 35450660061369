import { useEffect, useState } from "react";
import * as images from "../../assets/Images";
function loadImage(url,action) {
    let img = new Image();
    img.addEventListener("load",() => {
        action();
    });
    img.src = url ;
}

export default function ImageManager({ children,onComplete }) {
  const [loaded, setLoaded] = useState(0);
  const [total,] = useState(Object.keys(images).length)
  useEffect(() => {
    if(loaded >= total) onComplete && onComplete() ;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loaded,total])

  useEffect(() => {
    for (const key in images) {
      if (Object.hasOwnProperty.call(images, key)) {
        const image = images[key];
        loadImage(image,() => setLoaded((l) => l + 1) )
      }
    }
  }, []);
  return <>{children && children(loaded,total)}</>;
}

export function ImageLoader({onComplete }) {
return <ImageManager onComplete={() => {onComplete()}} >
          { (loaded,total) => {
              return <div className="imageManager">
                <div className="bg flex flexFont">
                  <div className="c">{loaded} / {total}</div>
                  <div className="cload" style={{height : `${(loaded / total) * 100}%`}}></div>
                </div>
              </div>
            }}

</ImageManager>
}