import { RESET_CALIBRATION, SET_ATTEMPTS_NBR, SET_CURRENT_ATTEMPTS, SET_DOCTOR, SET_MAX_SETTINGS_NBR, SET_SOLUTION_VALUE, SET_STAGE } from "../constants/calibrationManager";



const initialState = () => {
    return {
        settings: {
            attemptsNbr: 5,
            currentAttempts : 0,
            maxSettingsNbr: 5,
            stage: 0,
        },
        doctor : {
            code : "",
            text : "",
        },
        solutions: [
            {
                id: "Valproate",
                keyName: "Valproate",
                infoTxt: "info Valproate",
                scale: [0, 260],

                initialValue: 100,
                currentValue: 100,
                submittedValue : 100,
                lastSubmitValue: 100,

                closeFromTheLastTime: "=",
                step: 5,
                successInterval: [45, 55],
                
                status: false,
            },
            {
                id: "Clobazam",
                keyName: "Clobazam",
                infoTxt: "info Clobazam",
                scale: [0, 260],
                closeFromTheLastTime: "=",
                initialValue: 100,
                currentValue: 100,
                submittedValue : 100,
                lastSubmitValue: 100,

                step: 5,
                successInterval: [55, 65],
                status: false,
            },

            {
                id: "Stiripentol",
                keyName: "Stiripentol",
                infoTxt: "info Stiripentol",
                scale: [0, 260],
                closeFromTheLastTime: "=",
                initialValue: 100,
                currentValue: 100,
                submittedValue : 100,
                lastSubmitValue: 100,

                step: 5,
                successInterval: [240, 260],
                status: true,
            },
        ],
    };
};

function calibrationRootReducer(state = initialState(), action) {
    switch (action.type) {
        case SET_ATTEMPTS_NBR:
            return { ...state, settings: { ...state.settings, attemptsNbr: action.payload } };
        case SET_CURRENT_ATTEMPTS:
            return { ...state, settings: { ...state.settings, currentAttempts: action.payload } };

        case SET_MAX_SETTINGS_NBR:
            return { ...state, settings: { ...state.settings, maxSettingsNbr: action.payload } };
        case SET_STAGE:
            return { ...state, settings: { ...state.settings, stage: action.payload } };
            case SET_DOCTOR:
                return { ...state, doctor: { ...state.doctor, ...action.payload } };   
        case SET_SOLUTION_VALUE:
            if(action.payload.solutionId === "Clobazam" && action.payload.value.currentValue < 55) {
                return state ;
            }else if (action.payload.solutionId === "Valproate" && action.payload.value.currentValue < 45 ) {
                return state ;
            }
            return {
                ...state,
                solutions: state.solutions.map((solution) =>
                    solution.id === action.payload.solutionId
                        ? { ...solution, ...action.payload.value }
                        : solution
                ),
            };
            
        case RESET_CALIBRATION:
            return initialState();
        default:
            return state;
    }
}
export default calibrationRootReducer;

