export const FILL_BACTODEX         = 'FILL_BACTODEX';
export const ACCESS_TO_CHAPTER     = 'ACCESS_TO_CHAPTER';
export const EDIT_SCORE            = 'EDIT_SCORE';
export const UNLOCK_TESTLABO       = 'UNLOCK_TESTLABO';
export const UNLOCK_QUIZ_1         = 'UNLOCK_QUIZ_1';
export const UNLOCK_ANTIBIOGRAMME  = 'UNLOCK_ANTIBIOGRAMME';
export const UNLOCK_QUIZ_2         = 'UNLOCK_QUIZ_2';
export const UNLOCK_GUIDELINE      = 'UNLOCK_GUIDELINE';
export const UNLOCK_BOARD          = 'UNLOCK_BOARD'
export const REPLY_TO_TESTLABO     ='REPLY_TO_TESTLABO'
export const REPLY_TO_ANTIBIOGRAMME='REPLY_TO_ANTIBIOGRAMME'
export const REPLY_TO_QUIZ_1       ='REPLY_TO_QUIZ_1'
export const REPLY_TO_QUIZ_2       ='REPLY_TO_QUIZ_2'




