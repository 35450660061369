import { useTranslation } from "react-i18next";

export function SecurityContent() {
    const { t } = useTranslation(undefined, {
        keyPrefix: "general.popup_security",
    });

    return (
        <div className="content" style={{fontSize : "0.8em"}}>
            <h1 className="title">{t("title")}</h1>
            <section>
                <div>
                    <p>
                        <b>
                            <u>{t("p_0")}</u>
                        </b>
                    </p>
                    <br/>
                    
                    <p><u>{t("p_01")}</u></p>
                    <p>{t("p_02")}</p>
                    <p>{t("p_03")}</p>
                    <p><b>{t("p_04")}</b></p>
                    <p><u>{t("p_05")}</u></p>
                    <p>{t("p_06")}</p>
                    <p><u>{t("p_07")}</u></p>
                    <p>{t("p_08")}</p>
                    <p><u>{t("p_09")}</u></p>
                    <p>{t("p_010")}</p>
                    <p><u>{t("p_011")}</u></p>
                    <p>{t("p_012")}</p>

                    <br/>
                    <p><u>{t("p_013")}</u></p>

                    <p>{t("p_1")}</p>
                    <p>
                        <u>{t("p_2")}</u>
                    </p>
                    <p>{t("p_3")}</p>

                    <p>
                        <u>{t("p_4")}</u>
                    </p>
                    <p>{t("p_5")}</p>
                    <p>
                        <u>{t("p_6")}</u>
                    </p>
                    <p>{t("p_7")}</p>
                </div>

                <div>
                    <p>
                        <u>
                            <b>{t("p_8")}</b>
                        </u>
                    </p>
                    <br />
                    <p>
                        <u>{t("p_9")}</u>
                    </p>
                    <p>
                        <b>{t("p_10")}</b>
                    </p>
                    <p>{t("p_11")}</p>
                    <p>{t("p_12")}</p>
                    <p>{t("p_13")}</p>
                    <p>{t("p_14")}</p>
                    <p>{t("p_15")}</p>
                    <p>{t("p_16")}</p>
                    <p>{t("p_17")}</p>
                    <br />

                    <p>
                        <u>{t("p_18")}</u>
                    </p>
                    <p>{t("p_19")}</p>
                    <p>{t("p_20")}</p>
                    <p>{t("p_21")}</p>

                    <br />
                    <p>
                        <u>{t("p_22")}</u>
                    </p>
                    <p>{t("p_23")}</p>
                    <p>{t("p_24")}</p>
                    <p>
                        <u>{t("p_25")}</u>
                    </p>
                    <p>{t("p_26")}</p>
                    <p>
                        <b>{t("p_27")}</b>
                    </p>
                    <p>
                        <u>{t("p_28")}</u>
                    </p>
                    <p>{t("p_29")}</p>
                    <br />
                    <p>{t("p_30")}</p>
                </div>
            </section>
        </div>
    );
}
