import React from "react";
import Tube from "./Tube";
import EditDosage from "./EditDosage";
import Button from "../../widgets/button/Button";

const Solution = ({solution}) => {

  return (
    <div className={`solution`}>
      <div className="tube">
        <Tube
          value={solution.currentValue}
          scale={solution.scale}
          keyName={solution.keyName}
          status={solution.status}
        />
        <EditDosage
          step={-solution.step}
          solution={solution}
          currentValue={solution.currentValue}
          status={solution.status}
        />
        <EditDosage
          step={solution.step}
          solution={solution}
          currentValue={solution.currentValue}
          status={solution.status}
        />
      </div>
      
{/* 
      <Button

      onClick={() => edit(step)}
      src={step > 0 ? plus : minus}
      ratio={"9:1"}
      scale={15}
      top={"110%"} left={"50%"}

    /> */}

      {/* <Modal
        styleShowModalBtn={styleShowModalBtn}
        styleCloseModalBtn={styleCloseModalBtn}
        styleModalOverlay={styleModalOverlay}
        styleModalBox={styleModalBox}
        content={solution.infoTxt}
      /> */}

    </div>
  );
};

export default Solution;
