import { RESET_CALIBRATION, SET_ATTEMPTS_NBR, SET_CURRENT_ATTEMPTS, SET_DOCTOR, SET_MAX_SETTINGS_NBR, SET_SOLUTION_VALUE, SET_STAGE } from "../constants/calibrationManager";

export function setAttemptsNbrAction(value) {
    return { type: SET_ATTEMPTS_NBR, payload: value };
  }
  
  export function setCurrentAttemptsAction(value) {
    return { type: SET_CURRENT_ATTEMPTS, payload: value };
  }

  
  export function setMaxSettingsNbrAction(value) {
    return { type: SET_MAX_SETTINGS_NBR, payload: value };
  }
  
  export function setStageAction(value) {
    return { type: SET_STAGE, payload: value };
  }
  
  export function setSolutionValueAction(solutionId, value) {
    return { type: SET_SOLUTION_VALUE, payload: { solutionId, value } };
  }
  

  export function resetCalibrationAction() {
    return { type: RESET_CALIBRATION };
  }

  export function setDoctorAction(value) {
    return { type: SET_DOCTOR, payload: value };
  }
  