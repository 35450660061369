import { useEffect } from "react";
import { doctor_bubble } from "../assets/Images";
import doctor from "../assets/videos/doctor.webm";
import { initialFlexFont } from "../assets/styles/styles";
export function Doctor({text="",crop_buttom=100}) {
    useEffect(() => {
        initialFlexFont()
      })
    return <div className="doctor-size-position">
        <div className="doctor-cotainer">
            <video style={{clipPath: `polygon(0 0, 100% 0, 100% ${crop_buttom}%, 0 ${crop_buttom}%)`}} 
            className="doctor-animation" loop src={doctor} autoPlay={true} mute />
            <div style={{display : `${text !== "" ? "block" : "none" }`}} className="says-container flexFont">
                <div className="bubble-says">
                    <img className="bubble-says-background" alt="" src={doctor_bubble}/>
                    <p className="bubble-says-text">{text}</p>
                </div>
            </div>
        </div>
    </div>
}