import Screen from "./Screen";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { useAnimationFrame } from "../utils/hooks/useAnimationFrame";
import produce from "immer";
import { v4 as uuidv4 } from 'uuid';
import Button from "../widgets/button/Button";
import { arrows, background_guitarHero, blueButton, blueIllustration, bulbIllustration, orangeButton, orangeIllustration, redButton, redIllustration } from "../assets/Images";
import BottlesComp from "../components/guitarHero/Bottles";
import { onClickDelayed } from "../utils/hooks/click";
import { initialFlexFont } from "../assets/styles/styles";
import { removeAllAndAdd } from "../redux/actions/sceneManager";
import { CONST_SCREENS } from ".";
import { useTranslation } from "react-i18next";
import { Doctor } from "../components/Doctor";
import { musicAmbiance, soundFx } from "../assets/sounds/howler";
import { FX_SPRITE_NAMES } from "../assets/sounds/fx";
import {MUSIC_SPRITE_NAMES} from "../assets/sounds/music";
import { AUDIO_SPRITE_NAMES } from "../assets/sounds/audio";
import { audioAmbiance } from "../assets/sounds/howler";
import { LangContext } from "../translation/context_lang";
const MAX_PLAY_TIME = 20; 
let onClick = new onClickDelayed(100);
// strategy = % or px
const strategy = "%";
const COLORS = ['red', 'blue', 'orange']; 
const comboColor = ['#322e2f','#12a4d9','#d9138a','#e2d810'];
const getColor = (combo) => {
  if(combo >= 0 && combo <= 4) return comboColor[0];
  else if(combo >= 5 && combo <= 10) return comboColor[1]
  else if(combo >= 11 && combo <= 15) return comboColor[2]
  else return comboColor[3]
}
const randomIndexColor = () => Math.floor(Math.random() * COLORS.length)
const generateBullet = () => {
  return {
    id : uuidv4(),
    color : COLORS[randomIndexColor()],
    x : -10,
    hover : false,
    clicked : false,
    animation : ""
  }
}
const BULLET_SPEED = 0.10;
const APPEAR_SPEED = 750;
const GuitarHero =  props => {
  const dispatch = useDispatch();
  const [bullets,setBullets] = useState([]);
  const [bulletsInTheWay,setBulletsInTheWay] = useState([]); // correct bullet
  const [count, setCount] = useState(0); // timer
  const [reset,setReset] = useState(0);
  const [win,setWin] = useState(false);
  const [fill,setFill] = useState(false);
  const [hoverIndex, setHoverIndex] = useState({});
  const [running, setRunning] = useState(false);
  const [combo,setCombo] = useState(0)
  const IndicatorContainer = useRef();
  const CollistionContainer = useRef();
  const lastTime = useRef(0);
  const {t} = useTranslation();
  const {value : {isLangSet,lang}} = useContext(LangContext);
  useEffect(() => {
      musicAmbiance.stop();
      musicAmbiance.play(MUSIC_SPRITE_NAMES.Guitar_hero);
      audioAmbiance.stop();
      let sprite = audioAmbiance.play(AUDIO_SPRITE_NAMES["instruction-mission-1"])
    return () => {
      musicAmbiance.stop();
      audioAmbiance.stop(sprite);
      musicAmbiance.play(MUSIC_SPRITE_NAMES.Background);
      
    }
  },[]);

  useAnimationFrame((deltaTime,time) => {
    setBullets((prevBullets) => produce(prevBullets, (draftState) => {
      let {width : indicatorWidth, x : indicatorX } = IndicatorContainer.current.getBoundingClientRect() ;
      let {width : collistionWidth,x : collisionX } = CollistionContainer.current.getBoundingClientRect() ;
      let CollistionStart = indicatorWidth  - ( collisionX - indicatorX)  ;
      let CollistionEnd = CollistionStart - collistionWidth * 1.5;
  
      for (let index = 0; index < draftState.length; index++) {
        if(draftState[index] === undefined) return ;
        draftState[index].x += BULLET_SPEED * deltaTime * 0.15;
        if(draftState[index] === undefined) return ;

        if(strategy === "px") {
        // in pixel but need some modification in responsive => animation work smooth tho
        if(draftState[index].x > indicatorWidth) draftState.splice(index,1);
        if(draftState[index] === undefined) return ;
        if(draftState[index].x > CollistionEnd && draftState[index].x <  CollistionStart ) {
          draftState[index].hover = true
        }
        else {
          // console.log("not over",CollistionStart,draftState[index].x,CollistionEnd);
          // console.log("indicatorWidth",indicatorWidth);
          draftState[index].hover = false
        }
        }else {
        // in % good with resposnive but => i feel like the animation lagging
        if(draftState[index].x > 100) {
          if(!draftState[index].clicked) setCombo(0);
          draftState.splice(index,1);
          
        }
        if(draftState[index] === undefined) return ;
        const CollistionEndPercentage = ((indicatorWidth - CollistionEnd) / indicatorWidth) * 100;
        const CollistionStartPercentage = ((indicatorWidth - CollistionStart) / indicatorWidth) * 100 ;
        if(draftState[index].x > 100 - CollistionEndPercentage  && draftState[index].x <  100 - CollistionStartPercentage   ) {
          draftState[index].hover = true;
        }
        else {
          // console.log("not over",CollistionStart,draftState[index].x,CollistionEnd);
          // console.log("indicatorWidth",indicatorWidth);
          draftState[index].hover = false ;
        }
        }
      }
    }))

    if (time - lastTime.current > APPEAR_SPEED ) {
     
      let ok = randomIndexColor();
      
      if(ok === 0 || ok === 1 )  {
      setBullets((prevbullets) => {
        return [...prevbullets,generateBullet()]
      });
    }
    lastTime.current = time;
    }
    setCount(prevCount => (prevCount + deltaTime * 0.001));
  },running);


  const restart = () => {
    setReset(reset + 1);
    setBullets([]);
    setBulletsInTheWay([]);
    setCount(0);
  }

  const stopGame = () => {
    setRunning(false);
    setBullets([]);
    setFill(true);
  }

  useEffect(() => {
    if(win) return ;
    if(count >= MAX_PLAY_TIME) {
      setWin(true);
    } 
  },[count,win])

  useEffect(() => {
    if(win) return ;
    let countBullet = {
      red : 0,
      blue : 0,
      orange : 0,
    }

    bulletsInTheWay.forEach(bullet => {
      countBullet[bullet.color] = countBullet[bullet.color] + 1 ;
    });

    if(countBullet.red >= 5 && countBullet.blue >= 5 && countBullet.orange >= 5 ) {
      setWin(true);
    }

  },[bulletsInTheWay,win])

  useEffect(() => {
    if(win) {
      stopGame()
      setTimeout(() => {
        dispatch(removeAllAndAdd(CONST_SCREENS["AFTER_GUITAR_HERO"]));
      },1000)
    }
  },[win])

  const verify = useCallback(
      (color) => {
      const BulletShouldBeClicked = bullets.filter((bullet) => bullet.hover === true && bullet.clicked === false);
      const ALlColorOfTheBulletMustBeClicked = new Set(BulletShouldBeClicked.map((bullet) => bullet.color));
      const foundBullet = BulletShouldBeClicked.find(bullet => bullet.color === color);

      if(color === "red") soundFx.play(FX_SPRITE_NAMES.vintage_radio_button_006)
      else if (color === "orange") soundFx.play(FX_SPRITE_NAMES.light_switch_019);
      else if (color === "blue") soundFx.play(FX_SPRITE_NAMES.button_on_off_064);

      if(BulletShouldBeClicked.length === 0) return ;
      var indexFound = null ;
      let happens = false ;
      let setAnimation = (ani) => {
        onClick(() => {
          if(ani === "wrong") onClick(setCombo(0));
          else if(ani === "right") onClick(setCombo((C) =>   C + 1));
        })
      }
  
      setBullets((prevBullets) => {
        let next = produce(prevBullets, (draftState) => {
          for (let i = 0; i < prevBullets.length; i++) {
            const element = draftState[i];
            // is alrady clicked ? we don't need to verify it !
            if(element.clicked === true) continue ;
            // the clicked color is not in hover ? make the first bullet wrong !
            if( !ALlColorOfTheBulletMustBeClicked.has(color)) {
              if(element.hover && !element.clicked) {
                draftState[i].clicked = true; 
                draftState[i].animation = "wrong";
                setAnimation("wrong")
                break ;
              }
            }
    
            // the color is hovered and user clicked the color ? make the first hovered bullet right  
            if( foundBullet && foundBullet["id"] && foundBullet["id"] === element.id) {
              draftState[i].clicked = true; 
              draftState[i].animation = "right"; 
              setAnimation("right")
              indexFound = i ;
              break ;
            } 
          }
        })
        if(indexFound !== null && !happens) {
          setBulletsInTheWay((bullet) => [...bullet,next[indexFound]])
          happens = true ;
          soundFx.play(FX_SPRITE_NAMES.FUI_Navigation_Swipe_2);
        }
        
        return next ;
      })
    },[bullets])  

  useEffect(() => {
    const keyBindingVerify = (e) => {
      if(e.key === "F2") verify("blue")
      else if(e.key === "F1") verify("red")
      else if (e.key === "F3") verify("orange")
    }
    const preventDefault = (e) => {
      if(e.key === "F1" || e.key === "F2" || e.key === "F3") e.preventDefault();
    }
    window.addEventListener("keyup",keyBindingVerify);
    window.addEventListener("keypress",preventDefault);
    window.addEventListener("keydown",preventDefault);
    return () => {
      window.removeEventListener("keyup",keyBindingVerify);
      window.removeEventListener("keypress",preventDefault);
      window.removeEventListener("keydown",preventDefault);
    }
  },[verify])
  useEffect(() => {
    initialFlexFont()
  },[])
  return  <Screen component={props.props.component}>
    <div className="guitarHeroContainer">
      <div>
      <div className="backgroundGuitarHero">
        <div className="flexFont">
          {/* this place is relative to 100% */}
          <img className="background" src={background_guitarHero} alt=""></img>
          
          <img className="redIllustration" src={redIllustration} alt=""></img>
          <img className="blueIllustration" src={blueIllustration} alt=""></img>
          <img className="orangeIllustration" src={orangeIllustration} alt=""></img>
          <div key={combo} className="combo jello-vertical" style={{color : getColor(combo)}}><span className="x">X</span> <span className="number">{combo}</span></div>
          <Doctor />
        </div>
      </div> 


      <div className="guitarhero-Text-container heartbeat flexFont">
        <div>
        <div style={{width : "40%",padding : "2%",boxSizing :"border-box"}}>
        <img  style={{width : "100%", height : "100%",objectFit : "contain"}} src={bulbIllustration} alt=""></img>
        </div>  
        
        <div className="center-consigne">
          <div>
          <h1>{t("guitar_hero.instruction.title")}</h1>
          <p>{t("guitar_hero.instruction.instruction")}</p>
          </div>
          <h2>{t("guitar_hero.instruction.footer")}</h2>
        </div>
        </div>




      </div>


      <div className="indicators" ref={IndicatorContainer}>
        <div>
        <div className="collistion" ref={CollistionContainer}><div className="arrow"></div><img src={arrows} alt=""></img></div>
        {bullets.map((bullet,index) => {
          return <div key = {bullet.id}  data-id={bullet.id}  style={{right : `${bullet.x}${strategy}`}}  className={`bullet ${bullet.color} ${bullet.animation} ${bullet.hover ? "hover" : "nothover"}`}></div>
        })}
        </div>
      </div>
      <div className="bottles-container">
        <div>
          <BottlesComp lang={lang} key={reset} fill={fill} bulletsInTheWay={bulletsInTheWay} activeBlue activeRed activeOrange style={{height : "100%",width : "100%",objectFit : "contain"}}></BottlesComp>
        </div>
      </div>
      <div className="buttons">        
        <Button top={"75%"} left={"36.8%"} scale={8} src={redButton} onClick={() => {soundFx.play(FX_SPRITE_NAMES.vintage_radio_button_006);verify("red")}}></Button>
        <Button top={"75%"} left={"49.3%"} scale={8} src={orangeButton} onClick={() => {soundFx.play(FX_SPRITE_NAMES.light_switch_019);verify("orange")}}></Button>
        <Button top={"75%"} left={"61.5%"} scale={8} src={blueButton} onClick={() => {soundFx.play(FX_SPRITE_NAMES.button_on_off_064);verify("blue")}}></Button>


        {/* <Button top={"89%"} left={"33%"} className="flexFont primary" ratio={"15:6"} scale={1} onClick={() => setRunning((v) => !v)}><span style={{fontSize : "1.7em"}}>{running ? "stop" : "play"}</span></Button> */}
        {/* <Button top={"32%"} left={"72%"} >{Math.round(count)}</Button> */}
        {/* <Button top={"89%"} left={"53%"} className="flexFont primary" ratio={"15:6"} scale={1} onClick={() => restart()} ><span style={{fontSize : "1.7em"}}>restart</span></Button> */}
        {!running && <Button top={"89%"} left={"43%"} className="flexFont primary" ratio={"15:6"} scale={1} onClick={() =>  {soundFx.play(FX_SPRITE_NAMES.FUI_Ease_Into_Position);setRunning((v) => !v)}}><span style={{fontSize : "1.7em"}}>{t("guitar_hero.gui.button_text")}</span></Button>}

        <br/>
      </div>
      </div>
    </div>
    {/* The screen is relative and here we can add what we want here */}
  </Screen>
};

export default GuitarHero ;