import {
    CHANGE_CURRENT_QUIZ,
    UPDATE_CURRENT_QUIZ
} from "../constants/quizManager";
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    currentQuiz: 0, // quiz index between 0 and the (length - 1)  
    Quiz0: [
        "A votre avis, quelle bactérie est à l’origine de cette pathologie ? Cliquez sur la réponse qui vous semble correcte ! N’oubliez pas, le Bactodex est là pour vous aider !", // question
        {  // option 1
            id: uuidv4(),
            text: "Escherichia coli",
            notifText: `L’agent en cause n’est pas <span class="italic">Escherichia coli</span>.`,
            status: true,
            IsTrue: false,
        }, { // option 2
            id: uuidv4(),
            text: "Streptococcus pneumoniae",
            notifText: `L’agent en cause n’est pas <span class="italic">Streptococcus pneumoniae</span>`,
            status: true,
            IsTrue: false,
        }, { // option 3
            id: uuidv4(),
            text: "Haemophilus influenzae",
            notifText: `Effectivement Docteur, nous sommes en présence d’une RSBA
            à <span class="italic bold">Haemophilus influenzae</span>.<sup>3</sup>`,
            // "Retournons maintenant à la paillasse pour réaliser l’antibiogramme",
            status: true,
            IsTrue: true,
        }],
    // quiz 1
    Quiz1: [
        "Devant ce cas, quel traitement préconisez-vous ?", // question
        { // option 1
        id: uuidv4(),
        text: "Azithromycine",
        notifText: `Dans notre cas, <span class="italic bold">Haemophilus influenzae</span> n’est sensible qu’à 2,7% à l’azithromycine. Vous pouvez choisir une autre réponse.`,
        status: true,
        IsTrue: false,
    }, {// option 2
        id: uuidv4(),
        text: "Lévofloxacine",
        notifText: `Le risque d'effets indésirables graves associés aux fluoroquinolones, dépasse généralement leurs bénéfices chez les patients atteints de RSBA. La lévofloxacine n’est pas le bon choix.<sup>6</sup>`,
        status: true,
        IsTrue: false,
    }, { // option 3
        id: uuidv4(),
        text: "Amoxicilline/ acide clavulanique",
        notifText:`Bravo ! Vous avez choisi le bon traitement. Nous sommes face à une RSBA à <span class="italic">Haemophilus influenzae</span> et le traitement de choix est : <span class="bold">Amoxicilline/acide clavulanique</span>.<sup>7,8</sup>` ,
        status: true,
        IsTrue: true,
    }],

}

function quizManagerRootReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_CURRENT_QUIZ:
            return Object.assign({}, state, { currentQuiz: action.currentQuiz })
        case UPDATE_CURRENT_QUIZ:
            return Object.assign({}, state, { [`Quiz${state.currentQuiz}`]: action.payload });
        default:
            return state;
    }
} 
export default quizManagerRootReducer;