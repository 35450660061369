import scene from "./Scene";
import { useSelector, useDispatch } from "react-redux";
import { CONST_SCREENS } from "../../Screens";
import {
  addScene,
  deleteScene,
  changeOrderScene,
  removeAllAndAdd
} from "../../redux/actions/sceneManager";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";

const events =  [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
  'focus',
]

function SceneManager({ fn }) {
  const scenes = useSelector((state) => state.SceneManager.scenes);
  const dispatch = useDispatch();
  const onPresenceChange = (presence) => {
    // Handle state changes in one function
    if(presence.type === "idle") {
      dispatch(removeAllAndAdd(CONST_SCREENS["SPLASHSCREEN"]))
    }
  }

  const idleTimer = useIdleTimer({ onPresenceChange,timeout: 1000 * 60 * 4, events });
  return (
    <>
      {/* <DebugSceneManager scenes={scenes} /> */}
      <div id="SceneManager">
        <TransitionGroup>
          {scenes.map((props) => (
            <CSSTransition
              key={props._uid}
              timeout={300}
              classNames={props.animation ? props.animation : "item"}
            >
              {scene(props)}
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </>
  );
}

function DebugSceneManager({ scenes }) {
  const dispatch = useDispatch();
  function exist(key) {
    return scenes.filter((el) => el.component === key).length !== 0;
  }
  useEffect(() => {
    dragElement(document.getElementById("debugDiv"));

    function dragElement(elmnt) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + "header").onmousedown =
          dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
  }, []);
  return (
    <div
      id="debugDiv"
      style={{
        backgroundColor: "#282c34",
        maxHeight: "20vh",
        border: "2px solid white",
        position: "fixed",
        top: "10px",
        left: "50%",
        zIndex: "999",
        overflowY: "scroll",
        transform: "translate(-50%, 0)",
        resize: "vertical",
        minWidth: "40%",
      }}
    >
      <h3 style={{ textAlign: "center", color: "white" }} id="debugDivheader">
        drag
      </h3>
      {Object.keys(CONST_SCREENS).map((keyName) => (
        <div
          key={keyName}
          style={{
            margin: "10px 0",
            backgroundColor: "rgb(245 245 245)",
            padding: "3px",
            borderBottom: "3px solid #cccc3e",
          }}
        >
          {" "}
          <span>{keyName}</span>{" "}
          <button
            onClick={() =>
              dispatch(
                addScene(CONST_SCREENS[keyName], {

                  x: "t",
                  fn: () => alert("hello"),
                })
              )
            }
          >
            +
          </button>{" "}
          <button onClick={() => dispatch(deleteScene(CONST_SCREENS[keyName]))}>
            &times;
          </button>{" "}
          <button
            onClick={() => dispatch(changeOrderScene(CONST_SCREENS[keyName]))}
          >
            &#x2191;
          </button>{" "}
          <button
            style={{
              background: exist(CONST_SCREENS[keyName]) ? "blue" : "red",
            }}
          >
            exist
          </button>
        </div>
      ))}
    </div>
  );
}

export default SceneManager;
// scene(props)
