import React, {useCallback,useState } from "react";
import Button from "../../widgets/button/Button";
import { minus, plus } from "../../assets/Images";
import { useDispatch } from "react-redux";
import { setSolutionValueAction } from "../../redux/actions/calibrationManager";
import { soundFx } from "../../assets/sounds/howler";
import { FX_SPRITE_NAMES } from "../../assets/sounds/fx";
import { useInterval } from "../../utils/hooks/useInterval";
import { isBetween } from "../../utils";
const EditDosage = ({ step, solution,status }) => {
  const dispatch = useDispatch();
  const currentValue = solution.currentValue ;
  const [autoIncrement,setAutoIncrement] = useState(false);
  const _edit = (step) => {
    const nextCurrentValue = currentValue + step ;
    if (isBetween(nextCurrentValue ,...solution.scale)) {
      dispatch(setSolutionValueAction(solution.id,{currentValue : nextCurrentValue}) )
    }
  }
  const edit = useCallback((step) =>{
      if(step >= 0) {
        soundFx.play(FX_SPRITE_NAMES.Button_Click_01);
      }else {
        soundFx.play(FX_SPRITE_NAMES.Button_Click_03);
      }
      _edit(step);
      setAutoIncrement(true);
  },[solution])

  useInterval(() => {
    _edit(step);
  }, autoIncrement ? 150 : null)
  
  function clearTimers() {
    setAutoIncrement(false);
  }

  return (
    <Button
      visible={status}
      disabled={!status}
      // onClick={() => edit(step)}
      src={step > 0 ? plus : minus}
      ratio={"5:1"}
      scale={15}
      left={"90%"}
      onMouseUp={clearTimers}
      onMouseLeave={clearTimers}
      onMouseDown={() => edit(step)}
      top={step < 0 ? "50%" : "30%"}

    />
    // <button onMouseUp={clearTimers} onMouseLeave={clearTimers} disabled={isDisabled} onMouseDown={() => edit(step)}>
    //   {step > 0 ? "+" : "-"}
    // </button>
  );
};

export default EditDosage;
