import {useRef,useEffect} from "react";
import { audioAmbiance } from "../../assets/sounds/howler";
export function useVoice(spriteId,when) {
    const audioRef = useRef();
    
    useEffect(() => {
        if(when) {
            audioAmbiance.stop();
            audioRef.current = audioAmbiance.play(spriteId);
        }
        else {
            audioRef.current && audioAmbiance.stop(audioRef.current);
            audioRef.current = null ;
        }
        return () => {
            audioRef.current && audioAmbiance.stop(audioRef.current);
            audioRef.current = null ;
        }
    },[when]);
} 