import React from "react";
import Stiripentol from "./Tubes/Stiripentol";
import Valproate from "./Tubes/Valproate";
import Clobazam from "./Tubes/Clobazam";

const getClipPath = (percentage) => {
  return `polygon(0 100%, 100% 100%, 100% ${100 - percentage}%, 0% ${
    100 - percentage
  }%)`;
};
const Tube = ({ value, scale, keyName,status }) => {
  
  switch (keyName) {
    case "Stiripentol":
      return <Stiripentol
      value={value}
      clipPath={() => getClipPath((value * 100) / scale[1])}
      status={status}
      key={keyName}
      style={{clipPath: "polygon(0 0, 100% 0, 100% 80%, 31% 80%, 31% 100%, 0 100%)"}}
    />
    case "Valproate" : 
    return <Valproate
    value={value}
    clipPath={() => getClipPath((value * 100) / scale[1])}
    status={status}
    key={keyName}
    style={{clipPath: "polygon(0 0, 100% 0, 100% 80%, 31% 80%, 31% 100%, 0 100%)"}}
  />
    case "Clobazam": 
    return     <Clobazam
    value={value}
    clipPath={() => getClipPath((value * 100) / scale[1])}
    status={status}
    key={keyName}
    style={{clipPath: "polygon(0 0, 100% 0, 100% 80%, 31% 80%, 31% 100%, 0 100%)"}}
  />
    default:
      return <></>
  }

    


    // <div className="progressBar" style={{ textAlign: "left" }}>
    //   <div className="myBar">
    //     <span className="percent">{`${value}%`}</span>
    //     <div className={`progress `} style={{ position: "relative" }}>
    //       <div
    //         style={{
    //           width: `${(value * 100) / scale[1]}%`,
    //           background: "red",
    //           // background: `${colorBar} 0% 0% no-repeat padding-box`,
    //           position: "absolute",
    //           height: "100%",
    //         }}
    //       ></div>
    //     </div>
    //   </div>
    // </div>

};

export default Tube;
