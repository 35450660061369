import { Trans } from 'react-i18next'

export function ColorfulTranslation({t,i18nKey}) {
    
    const spanBlue = <span className="blue"></span>;
    const spanPantone = <span className="pantone"></span>;
    const spanOrange = <span className="orange"></span>;
    const spanCrayola = <span className="crayola"></span>;
    const supText = <sup></sup>;
    const supBlue = <sup className="blue"></sup>;
    const supPantone = <sup className="pantone"></sup>;
    const supOrange = <sup className="orange"></sup>;
    const supCrayola = <sup className="crayola"></sup>;

    return <Trans i18nKey={i18nKey} t={t} components={[spanBlue,spanPantone,spanOrange,spanCrayola,supText,supBlue,supPantone,supOrange,supCrayola]} />
}
//      0       1           2           3           4       5       6           7       8
// [spanBlue,spanPantone,spanOrange,spanCrayola,supText,supBlue,supPantone,supOrange,supCrayola]