import { useContext, useEffect } from "react";
import Screen from "./Screen";
import { CONST_SCREENS } from "../Screens";
import { addScene, deleteScene } from "../redux/actions/sceneManager";
import { useDispatch } from "react-redux";
import { flexFontStyle } from "../assets/styles/styles";
import Button from "../widgets/button/Button";
import { control_logo, splashscreen_bg } from "../assets/Images";
import { LangContext } from "../translation/context_lang";
import { useTranslation } from "react-i18next";
import { changeContextAudio, soundFx } from "../assets/sounds/howler";
import { FX_SPRITE_NAMES } from "../assets/sounds/fx";


const SplashScreen = (props) => {
  const dispatch = useDispatch();
  const {i18n,t} = useTranslation();
  const {value : {isLangSet,lang},changeValue} = useContext(LangContext);
  
  const handlePlay = () => {
    soundFx.play(FX_SPRITE_NAMES.FUI_Ease_Into_Position);
    dispatch(
      addScene(CONST_SCREENS["INTRO"], {
        x: "t",
        fn: () => alert("hello"),
      })
    );
    dispatch(deleteScene(CONST_SCREENS["SPLASHSCREEN"]))
  };
  useEffect(() => {
    flexFontStyle();
  }, []);

  const setLanguage = (lang) => {
    soundFx.play(FX_SPRITE_NAMES.FUI_Ease_Into_Position);
    i18n.changeLanguage(lang);
    changeValue({isLangSet : true, lang});
    changeContextAudio(lang);
  }
  return (
    <Screen component={props.props.component}>
      <Background />
      {!isLangSet && (
        <>
      <Button top={"75%"} left={"50%"} className="flexFont primary" ratio={"15:6"} scale={1.3} onClick={() => setLanguage("en")}>
        <span style={{fontSize :"2em"}}>English</span>
      </Button> 
      <Button top={"75%"} left={"30%"} className="flexFont primary" ratio={"15:6"} scale={1.3} onClick={() => setLanguage("fr")}>
            <span style={{fontSize :"2em"}}>Français</span>
        </Button> 
        </>
      )}
      {isLangSet && <Button top={"75%"} left={"40%"} className="flexFont primary" ratio={"15:6"} scale={1.3} onClick={handlePlay}>
        <span style={{fontSize :"2em"}}>{t("introduction.splashscreen.start")}</span>
      </Button> }
      <div className="flexFont">
        <div className="codeReg">
          BC-23.24/INT
        </div>
      </div>
    </Screen>
  );
};

export default SplashScreen;

function Background() {
  return (
    <div className="screen-background">
      <div>
          <img style={{position :"absolute"}}  height={"100%"} className="floating bg"  src={splashscreen_bg} alt=""  />
          <img className="floatingPlanet" alt="" style={{position :"absolute",left : "50%",top : "30%",transform :"translate(-50%,-50%)"}} height={"40%"} src={control_logo} />
          
      </div>
    </div>
  );
}
