import { useEffect, useRef } from "react";

export const useAnimationFrame = (callback,running) => {
    // Use useRef for mutable variables that we want to persist
    // without triggering a re-render on their change
    const requestRef = useRef();
    const previousTimeRef = useRef();
    const wasRunningRef = useRef(running);
    const animate = time => {
        if (previousTimeRef.current != undefined) {
            if(wasRunningRef.current === false) {
                previousTimeRef.current = time;
                wasRunningRef.current = true ;
            }
            
            const deltaTime = time - previousTimeRef.current;
            callback(deltaTime,previousTimeRef.current)
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    }



    useEffect(() => {
        if(running) requestRef.current = requestAnimationFrame(animate);
        else {
            cancelAnimationFrame(requestRef.current);
            wasRunningRef.current = false ;
        }
        return () => cancelAnimationFrame(requestRef.current);
    }, [running]); // Make sure the effect runs only once
}